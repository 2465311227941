import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './SalesOrderReport.scss';

class SalesOrderReport extends Component{
  constructor(props){
    super(props);
    // this.districtSelect = React.createRef();
    this.executiveSelect = React.createRef();


    this.state = {
      zones    :   [],
      zone :   {},
      // districts    :   [],
      // district :   {},
      executives :  [],
      executive :  {},
      customers : [],
      showing_customers:[],
      search_customer:'',
      search_status : '',
      statuses : [ {'label' : 'IN PROGRESS',  'value': 'IN PROGRESS'},
      {'label' : 'DISPATCHED','value': 'DISPATCHED'},
      {'label' : 'CANCELLED', 'value': 'CANCELLED'}],
      selStatus : {},
      from_date : new Date(),
      to_date : new Date(),
      minDate : new Date(new Date().getFullYear()-1, new Date().getMonth(), 1 ),
      maxDate : new Date(new Date().getFullYear(), new Date().getMonth()+1, 0),
    }
    this.zoneChange = this.zoneChange.bind(this);
    // this.districtChange = this.districtChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    // const url = `${process.env.REACT_APP_SSS}/api/get/all/districts`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url,
    //   data : {
    //     sid : this.context.user_details.sid
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   // console.log(return_data);
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      districts : return_data.districts,
    //     });
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //     obj_this.context.loading(0);
    //     alert("Network error");
    // });

    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/mappedZones`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          zones : return_data.zone_mapped,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
   }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
        obj_this.props.history.replace("/");
    });
    
    const url3 = `${process.env.REACT_APP_SSS}/api/getOutstandingExecutiveDuration`;
    this.context.loading(1);
    $.ajax({
      url : url3,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
   
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/reports/all/getSalesOrderreport`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      // customers = customers.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.customers,
         showing_customers:return_data.customers
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);

    });

    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_schedule =[];
    if(e.target.name =="customer"){
      if(e.target.value){
        showing_schedule = this.state.showing_customers.filter((item) => {return item.dealer_name?item.dealer_name.toLowerCase().includes(e.target.value.toLowerCase()):''});
      }
      else{
        showing_schedule = this.state.customers;
      }
    }else{
      if(e.target.value){
        showing_schedule = this.state.showing_customers.filter((item) => {return item.new_status?item.new_status.toLowerCase().includes(e.target.value.toLowerCase()):''});
      }
      else{
        showing_schedule = this.state.customers;
      }

    }
    // showing_customers = showing_customers.length?showing_customers:this.state.doc_name;
    this.setState({
      showing_customers : showing_schedule
    });
  }

  zoneChange(option){
    this.setState({
      zone : option
    });
  } 

  // districtChange(option){
  //   this.setState({
  //     district : option
  //   });
  // } 
  executiveChange(option){
    var obj_this = this;
    // console.log(option);
    this.setState({
      executive : option,
      zone : null
      // district : null
    });
    const url = `${process.env.REACT_APP_SSS}/api/get/mappedZones`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        executive_id:  option.value
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         zones : return_data.zone_mapped,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
   }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
        obj_this.props.history.replace("/");
    });
    // const url = `${process.env.REACT_APP_SSS}/api/get/executive/wise/districts`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url,
    //   data : {
    //     sid : this.context.user_details.sid,
    //     executive_id:  option.value
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   // console.log(return_data);
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      districts : return_data.districts,
    //     });
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //     obj_this.context.loading(0);
    //     alert("Network error");
    // });
  } 

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.executive){
    //   this.executiveSelect.current.focus();
    //   return;
    // }
    
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/reports/all/getSalesOrderreport`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        executive : this.state.executive?this.state.executive.value:null,
        zone_id : this.state.zone?this.state.zone.value:null,
        // district_id : this.state.district?this.state.district.value:null,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA'),
        status : this.state.selStatus
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      // customers = customers.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        const statusMappings = {
          "MCNF":"WAITING ASM APPROVAL",
          "CNF":"SO LEVEL 1",
          "HOLD":"SO LEVEL 1 HOLD",
          "APR":"SO LEVEL 2",
          "SO"  : "SO VERIFIED",
          "SOCANCEL":"CANCELLED",
          "LEVEL 2 CLOSE":"CANCELLED",
          "LEVEL 1 CLOSE":"CANCELLED",
          "BILL CANCEL":"CANCELLED",
          "ACCOUNTS" : "ACCOUNTS",
          "TRIP IN YARD" : "TRIP IN YARD",
          "TRIP IN GASHA" : "TRIP IN GASHA",
          "CLO":  "CANCELLED",
          "TRIP":"TRIP",
          "INVOICE":"INVOICED",
          "DISPATCHED" : "DISPATCHED",
        };
        const updatedData = return_data.customers.map(obj => {
          const newObj = {...obj}
          newObj.new_status = statusMappings[newObj.status]
          return newObj;
        });
        // console.log(updatedData)
        // return
        obj_this.setState({
         customers :  updatedData,
         showing_customers: updatedData
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
   
    
  }


  fromDateChange(value){
    // console.log(value);
    this.setState({from_date : value});
  }
  toDateChange(value){
    // console.log(value);
    this.setState({to_date : value});
  }

  statusChange(option){
    this.setState({
      selStatus : option
    });
  } 



  
    

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="sales_order_report_page_container">
          <div className="page_header">
            <Link className="back_button" to="/reports"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Sales Order Report</div>
          </div> 
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
              <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      minDate = {this.state.minDate}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Order Status</div>
                  <Select 
                    options={this.state.statuses}  
                    value ={this.state.selStatus}
                    className="executive" 
                    // ref={this.districtSelect}
                    onChange={e => this.statusChange(e)}
                    // required
                  />
                  <div className="input_label">Executives</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    ref={this.executiveSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                   <div className="input_label">Zones</div>
                  <Select 
                    options={this.state.zones}  
                    value ={this.state.zone}
                    className="executive" 
                    onChange={e => this.zoneChange(e)}
                    // required
                  />
                  {/* <div className="input_label">Districts</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="executive" 
                    ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    // required
                  /> */}
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
                </div>
                
            </div>
          </form>
          <div className="grid_search">
            <div className="search_container">
              <CgSearch/>
              <input className="customer_search_input" 
                placeholder="Search Customers.."
                onChange={this.formChange}
                value={this.search_customer}
                name = "customer"
              />
              
            </div>
            <div className="search_container">
            <CgSearch/>
              <input className="customer_search_input" 
                placeholder="Filter Status.."
                onChange={this.formChange}
                value={this.search_status}
                name = "status"
              />
            </div>
          </div>
         
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{minWidth:"80px"}}>SO No</th>
                  <th style={{minWidth:"80px"}}>SO Date</th>
                  <th style={{minWidth:"120px"}}>Customer</th>
                  <th style={{minWidth:"80px"}}  align="center">Entered By</th>
                  <th style={{minWidth:"220px"}} align="center">Details</th>
                  <th style={{minWidth:"80px"}}  align="center">Status</th>
                  <th style={{minWidth:"80px"}}>Delivery Date</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_customers.length?"none":"table-row"}}>
                <td colSpan="8" align="center">No result!</td>
              </tr>
              {this.state.showing_customers && this.state.showing_customers.map((cust,index) => (
                <tr key={index}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" align="center">{cust.so_no}</td>
                  <td valign="top" align="center">{new Date(cust.order_date).toLocaleDateString('en-GB')}</td>
                  <td valign="top" >{cust.dealer_name}</td>
                  <td valign="top" align="center">{cust.staffname}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div className="bills_table_container" style={{display:cust.products.length?"block":"none"}}>
                      <table className="bills_table">
                        <thead>
                          <tr>
                            <th>Products</th>
                            <th>Qty</th>
                            <th>Unit</th>
                            <th>Unit Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cust.products && cust.products.map((product,idx) => (
                          <tr key={idx}>
                            <td>{product.name}</td>
                            <td>{product.qty}</td>
                            <td>{product.unit}</td>
                            <td>{product.qty>0?parseFloat(product.sub_total/product.qty).toFixed(3):0}</td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td valign="top" align="center">
                    { 
                      cust.new_status??cust.status
                    }
                  </td>
                  <td valign="top" align="center">
                    {new Date(cust.delivery_date).toLocaleDateString('en-GB')}
                  </td>

                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default SalesOrderReport;
SalesOrderReport.contextType = MyContext;
