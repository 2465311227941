
import {Link} from "react-router-dom";

import './ReportList.scss';

import { CgList, CgUserAdd } from 'react-icons/cg';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaMoneyCheckAlt } from 'react-icons/fa';

import React, { useContext } from 'react';
import MyContext from '../MyContext.jsx';




const Reportlist = () => {
  const userDetails = useContext(MyContext);

  return (
    <div className="report_page_container">
      <div className="menu_container">
  
        
        <Link to="/salesreport" className="single_menu_container" >
            <img className="company_logo"  src={window.project_path+"/dashboard/Sales_Report.png"} height="100px;" />
        </Link>
      
        
        <Link to="/dealerwisereport" className="single_menu_container">
            <img className="company_logo"  src={window.project_path+"/dashboard/dealer_Price_Report.png"} height="100px;" />
        </Link>

        
        <Link to="/executivewisereport"  className="single_menu_container">
            <img className="company_logo"  src={window.project_path+"/dashboard/Executive_wise_Report.png"} height="100px;" />
        </Link>
      
        <Link to="/salesorderreport" className="single_menu_container">
            <img className="company_logo"  src={window.project_path+"/dashboard/Sales_Order_Report.png"} height="100px;" />
        </Link>

       
        <Link to="/averagepricereport" className="single_menu_container">
            <img className="company_logo"  src={window.project_path+"/dashboard/Average_Price_Report.png"} height="100px;" />
        </Link>

        <Link to="/executivetargetreport" className="single_menu_container">
            <img className="company_logo"  src={window.project_path+"/dashboard/executivetargetreport.png"} height="100px;" />
        </Link>
        
        </div>
    </div>
  )
}

export default Reportlist;

