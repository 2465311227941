import React, { Component } from "react";
import axios from "axios";
// import $ from 'jquery';
import Select from "react-select";
import MyContext from '../MyContext.jsx';
import './Login.scss';
var apk_url = '';
class Login extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      options : [],
      username : "",
      password : "",
      division_id : "", 
      firebasetoken : ""    
    }
    this.formChange = this.formChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getDevicePlatform = this.getDevicePlatform.bind(this);
    this.getDeviceModel = this.getDeviceModel.bind(this);
  }

  componentDidMount(){ 
    var user_details = JSON.parse(window.localStorage.getItem('user_details_kalliyath_collection_emak_app'));
    if(user_details){
      this.setState({
        username : user_details.username,
        password : user_details.password,
      });
    }
    var obj_this = this;
    const sid = user_details && user_details.sid ? user_details.sid : "";
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_SSS +"/api/collection/checkLoginToken",
      data : {
        "sid" : sid,
        "version" : this.context.version
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if("divisions" in return_data){
        obj_this.setState({
          options : return_data.divisions,
        });
      }
      if(return_data.status == "success"){
        delete return_data.status;
        obj_this.context.setUserDetails(return_data);
        return_data.username  = user_details.username;
        return_data.password  = user_details.password;
        window.localStorage.setItem("user_details_kalliyath_collection_emak_app",JSON.stringify(return_data));
        obj_this.props.history.replace('/home');
        
      }else if(return_data.status == "fail"){
        if("url" in return_data){
          $(".update_apk_container, .ghost").css("display","block");
          apk_url = return_data.url;
        }else{
          $(".login_error").css("display","block");
        }
      }
      $(".login_page_container").css("visibility","visible");
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error22");
    });

    $(".update_apk_container").on("click",function(){
      var ref = cordova.InAppBrowser.open(apk_url, '_system');
    });

  }

  categoryChange(option){
    // console.log(option);
    this.setState({
      division_id : option.value
    });    
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  submitForm(e){
    e.preventDefault();
    if(this.state.division_id == ""){
      this.refs.focus();
      return;
    }

    this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/collection/login`;
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        "username" : this.state.username,
        "password" : this.state.password,
        "division_id" : this.state.division_id,
        "version" : this.context.version
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        delete return_data.status;
        obj_this.context.setUserDetails(return_data);
        window.localStorage.setItem("user_details_kalliyath_collection_emak_app",JSON.stringify(return_data));
        if (window.cordova) {
          const messaging = cordova.plugins.firebase.messaging;
          messaging.requestPermission()
            .then(() => messaging.getToken())
            .then((token) => {
              console.log('Firebase Token:', token);
              const devicePlatform = obj_this.getDevicePlatform();
              const deviceModel = obj_this.getDeviceModel();
              const url = `${process.env.REACT_APP_SSS}/api/save/firebase_token`;
              // alert(token)
              $.ajax({
                url : url,
                data : {
                  "sid" : obj_this.context.user_details.sid,
                  "token" : token,
                  "devicePlatform": devicePlatform,
                  "deviceModel": deviceModel,
                },
                type : "POST"
              }).done(function(return_data){
                console.log(return_data);
                if(return_data.status == "success"){
                  obj_this.context.loading(0);
                  return true;
                }else{
                  obj_this.context.loading(0);
                  alert("Notification Issue");
                  return false;
                }
              });
            })
            .catch((error) => {
              console.error('Error getting Firebase token:', error);
            });
        }
        obj_this.context.loading(0);
        obj_this.props.history.replace('/home');
      }else{
        $(".login_error").css("display","block");
      }
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }

  getDevicePlatform = () => {
    if (window.cordova && window.device && window.device.platform) {
      return window.device.platform;
    } else if (navigator.userAgent.match(/Android/i)) {
      return 'Android';
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  // Function to get the device model using cordova-plugin-device
  getDeviceModel = () => {
    if (window.cordova && window.device && window.device.model) {
      return window.device.model;
    } else {
      return 'Unknown';
    }
  };
  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="login_page_container">
          <div className="footer">
            <div className="developed_by">Developed by</div>
            <img src={window.project_path+"/images/ekatra-logo.png"} height="35px" />
            <div className="version">Ver {this.context.version}</div>
          </div>
          <form className="login_form"
            onSubmit={e =>this.submitForm(e)}
          >
            {/*<img className="company_logo" src="images/wwp_logo.png" height="50px;" /> */}
            <b style={{fontSize: "36px",color:"#ff0202"}}>E-MAK</b>
            <div className="input_container">
              <div className="input_icon"><i className="fas fa-user"></i></div>
              <input 
                type="text" 
                name="username" 
                placeholder="Username" 
                required 
                value = {this.state.username}
                onChange={this.formChange}
                autoComplete="off"
              />
            </div>
            <div className="input_container">
              <div className="input_icon"><i className="fas fa-lock"></i></div>
              <input 
                type="password" 
                name="password" 
                placeholder="Password" 
                required
                value={this.state.password}
                onChange={this.formChange}
                autoComplete="off"
              />
            </div>
            <div className="select_container">
              <Select 
                options={this.state.options}  
                // defaultValue={{ label: "Division", value: 0 }}
                placeholder={<div className="pholder">Branch</div>} 
                ref={r => {
                  this.refs = r;
                }} 
                className="division_select" 
                onChange={e => this.categoryChange(e)}
                required
              />
              <div className="input_icon"><i className="fas fa-building"></i></div>
            </div>
            <div className="login_error">*Credentials incorrect</div>
            <button 
              className="login_button" 
              type="submit"
            >Login</button>
          </form>
        
          
          <div className="ghost"></div>
          <div className="update_apk_container">
            <div className="update_message">You are using outdated version. Download new app</div>
            <div className="update_apk_button"><i className="fas fa-download"></i> Download</div>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Login;
Login.contextType = MyContext;