import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './AveragePriceReport.scss';

class AveragePriceReport extends Component{
  constructor(props){
    super(props);
    this.zoneSelect = React.createRef();
    // this.districtSelect = React.createRef();

    this.state = {
      zones    :   [],
      zone :   {},
      // districts    :   [],
      // district :   {},
      customers : [],
      showing_customers:[],
      categories    :   [],
      category :   {},
      dealers:[],
      dealer:{},
      search:'',
      from_date : new Date(),
      to_date : new Date(),
      minDate : new Date(new Date().getFullYear()-1, new Date().getMonth(), 1 ),
      maxDate : new Date(new Date().getFullYear(), new Date().getMonth()+1, 0),
    }
    this.zoneChange = this.zoneChange.bind(this);
    // this.districtChange = this.districtChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/mappedZones`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         zones : return_data.zone_mapped,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
   }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
        obj_this.props.history.replace("/");
    });
    // const url = `${process.env.REACT_APP_SSS}/api/get/all/districts`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url,
    //   data : {
    //     sid : this.context.user_details.sid
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   console.log(return_data);
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      districts : return_data.districts,
    //     });
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //     obj_this.context.loading(0);
    //     alert("Network error");
    // });

    const url3 = `${process.env.REACT_APP_SSS}/api/get/productCategories`;
    this.context.loading(1);
    $.ajax({
      url : url3,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         categories : return_data.categories,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });

   
    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_schedule =[];
    if(e.target.value){
      showing_schedule = this.state.showing_customers.filter((item) => {return item.name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_schedule = this.state.customers;
    }
    // showing_customers = showing_customers.length?showing_customers:this.state.doc_name;
    this.setState({
      showing_customers : showing_schedule
    });
  }

  zoneChange(option){
    this.setState({
      zone : option,
      dealer : ''
    });
    var obj_this = this;

    const url = `${process.env.REACT_APP_SSS}/api/get/getZoneWiseDealerList`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        zone_id : option.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         dealers : return_data.dealers,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
        obj_this.props.history.replace("/");
    });

  } 

  // districtChange(option){
  //   this.setState({
  //     district : option,
  //     dealer : ''
  //   });
  //   var obj_this = this;

  //   const url = `${process.env.REACT_APP_SSS}/api/get/getDistrictWiseDealers`;
  //   this.context.loading(1);
  //   $.ajax({
  //     url : url,
  //     data : {
  //       sid : this.context.user_details.sid,
  //       district_id : option.value
  //     },
  //     type : "POST"
  //   }).done(function(return_data){
  //     console.log(return_data);
  //     if(return_data.status == "success"){
  //       obj_this.setState({
  //        dealers : return_data.dealers,
  //       });
  //       obj_this.context.loading(0);
  //     }else{
  //       obj_this.context.loading(0);
  //       obj_this.props.history.replace("/");
  //     }
  //   }).fail(function(){
  //       obj_this.context.loading(0);
  //       alert("Network error");
  //   });

  // } 
  categoryChange(option){
    this.setState({
      category : option
    });
  }  
  dealerChange(option){
    this.setState({
      dealer : option
    });
  }  

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.district.value){
    //   this.districtSelect.current.focus();
    //   return;
    // }
    
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/reports/getAveragePricereport`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        zone_id : this.state.zone?this.state.zone.value:null,
        // district_id : this.state.district.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA'),
        category_id : this.state.category.value,
        dealer_id : this.state.dealer.value
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // customers = customers.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.customers,
         showing_customers:return_data.customers,
         total_qty  : return_data.total_qty,
         total_price : return_data.total_price,
         total_avg_price  : return_data.total_avg_price
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
   
    
  }


  fromDateChange(value){
    // console.log(value);
    this.setState({from_date : value});
  }
  toDateChange(value){
    // console.log(value);
    this.setState({to_date : value});
  }



  
    

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="average_report_page_container">
          <div className="page_header">
            <Link className="back_button" to="/reports"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Average Price Report</div>
          </div> 
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
              <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      minDate = {this.state.minDate}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Product Category</div>
                  <Select 
                    options={this.state.categories}  
                    value ={this.state.category}
                    className="executive" 
                    // ref={this.districtSelect}
                    onChange={e => this.categoryChange(e)}
                    // required
                  />
                  <div className="input_label">Zones</div>
                  <Select 
                    options={this.state.zones}  
                    value ={this.state.zone}
                    className="executive" 
                    ref={this.zoneSelect}
                    onChange={e => this.zoneChange(e)}
                  />
                  {/* <div className="input_label">Districts</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="executive" 
                    ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                  /> */}
                  <div className="input_label">Dealers</div>
                  <Select 
                    options={this.state.dealers}  
                    value ={this.state.dealer}
                    className="executive" 
                    // ref={this.districtSelect}
                    onChange={e => this.dealerChange(e)}
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
                </div>
                
            </div>
          </form>
          <div className="search_container">
            <CgSearch/>
            <input className="customer_search_input" 
              placeholder="Search Customers.."
              onChange={this.formChange}
              value={this.search}
            />
          </div>
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{minWidth:"90px"}} align="center">SO No</th>
                  <th style={{minWidth:"70px"}} align="center">SO Date</th>
                  <th style={{minWidth:"80px"}} align="center">Customer</th>
                  <th style={{minWidth:"80px"}} align="center">Vehicle No</th>
                  <th style={{minWidth:"80px"}} align="center">Bill Date</th>
                  <th style={{minWidth:"80px"}} align="center">Qty</th>
                  <th style={{minWidth:"80px"}} align="center">Average Unit Price</th>
                  <th style={{minWidth:"80px"}} align="center">Total Price</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_customers.length?"none":"table-row"}}>
                <td colSpan="10" align="center">No result!</td>
              </tr>
              {this.state.showing_customers && this.state.showing_customers.map((cust,index) => (
                <tr key={index}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" align="center">{cust.so_no}</td>
                  <td valign="top" align="center">{new Date(cust.order_date).toLocaleDateString('en-GB')}</td>
                  <td valign="top" >{cust.name}</td>
                  <td valign="top" >{cust.vehicle_no}</td>
                  <td valign="top" align="center">{new Date(cust.bill_date).toLocaleDateString('en-GB')}</td>
                  <td valign="top" align="center" >{cust.qty}</td>
                  <td valign="top" align="center">{parseFloat(cust.tot_price/cust.qty).toFixed(2)}</td>
                  <td valign="top" align="center">{cust.tot_price}</td>
                </tr>
              ))}
              <tr style={{display:this.state.showing_customers.length?"table-row":"none"}}>
                <td colSpan="6" style={{fontSize:'12px',fontWeight:"800"}}align="right">Total</td>
                <td colSpan="1" style={{fontSize:'12px',fontWeight:"800"}}align="center">{this.state.total_qty}</td>
                <td colSpan="1" style={{fontSize:'12px',fontWeight:"800"}}align="center">Average Price: {this.state.total_avg_price} <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span></td>
                <td colSpan="1" style={{fontSize:'12px',fontWeight:"800"}}align="center">{this.state.total_price} <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span></td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default AveragePriceReport;
AveragePriceReport.contextType = MyContext;
