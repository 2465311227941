import React, { Component } from "react";
// import { render } from "react-dom";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";

import './NewLeads.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';
var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
var customer_XHR={'abort' : ()=>{}};
var singleCustomer_DOM;

class NewLeads extends Component {
  constructor(props) {
    super(props);
    this.districtSelect = React.createRef();
    this.statusSelect = React.createRef();
    this.influencerSelect = React.createRef();
    this.state = {
      lead_id : '',
      mobile_no : '',
      customer_name : '',
      customer_id : '',
      enq_types : [],
      enquiry_from : '208',
      districts : [],
      district : {},
      location : '',
      enq_statuses : [],
      enq_status : {},
      followup_date : tomorrow,
      influencers : [],
      influencer : {},
      lead_types : [],
      lead_type : '',
      products : [],
      rows: [{
        product : {},
        specifications : '',
        qty : '',
        units : [],
        unit: {},
        total : '',
      }],
      grand_total : '',
      remarks : ''
    }
    this.formChange = this.formChange.bind(this);
    this.enquiryFromChange = this.enquiryFromChange.bind(this);
  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    singleCustomer_DOM = $(".single_customer_details").clone();
    $(".single_customer_details").remove();
    var obj_this = this;
    if(window.cordova){
      geo.getLocation(function(location){
        obj_this.context.setLocation(location);
      });
    }
    const {pathname} = this.props.location;
    if(pathname === "/leads/new") {
      const url = `${process.env.REACT_APP_SSS}/api/getExeLeadDistricts`;
      this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          const products = return_data.products.filter((item) => item.units.length);
          obj_this.setState({
            districts : return_data.districts,
            enq_statuses : return_data.enq_statuses,
            influencers : return_data.influencers,
            lead_types : return_data.lead_types,
            lead_type : return_data.lead_types[0]['id'],
            enq_types : return_data.enq_types,
            enquiry_from : return_data.enq_types[0]['id'],
            products : products,
            rows : [{
              product : {},
              specifications : '',
              qty : '',
              units : [],
              unit: {},
              total : '',
            }]
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }else{
      const lead_id = this.props.match.params.lead_id;
      const url = `${process.env.REACT_APP_SSS}/api/editLead`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          lead_id : lead_id
        },
        type : "POST"
      }).done(function(return_data){
        // console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var state_object = return_data.state_object;
          state_object.followup_date = epochToDate(state_object.followup_date);
          state_object.products = state_object.products.filter((item) => item.units.length);
          if(!state_object.rows.length){
            const item = {
              product : {},
              specifications: "",
              qty: "",
              unit : {}
            };
            state_object.rows.push(item);
          }
          obj_this.setState(state_object,() => {
            if(state_object.rows.length>1){
              $(".minimize_button").trigger("click");
            }
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }

    $(".customer_suggestions_container").on("mousedown",".single_customer_details",function(){
      $(".customer_suggestions_container").empty().css("display","none");
      const customer_id = $(this).attr("customer_id");
      obj_this.context.loading(1);
      const url = `${process.env.REACT_APP_SSS}/api/leadCustomerDetails`;
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          customer_id : customer_id
        },
        type : "POST"
      }).done(function(return_data){
        // console.log(return_data);
        var option = obj_this.state.districts.filter(item => item.value == return_data.customer.district_id);
        // console.log(option);
        option = option[0]?option[0]:{};
        obj_this.setState({
          mobile_no : return_data.customer.customer_phone,
          customer_name : return_data.customer.customer_name,
          location : return_data.customer.address,
          district : option,
          customer_id : customer_id
        });
        obj_this.context.loading(0);
      });
    })

    $(".mobile_no").on("focusout",function(e){
      // console.log(e.target);
      $(".customer_suggestions_container").empty().css("display","none");
    });

    $(".location_button").on("click",function(){
      if(window.cordova){
        var location_coordinates = obj_this.context.location;
        if(!location_coordinates.latitude){
          geo.getLocation(function(location){
            location_coordinates = location;
          });
        }else{
          obj_this.context.loading(1);
          const  url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+location_coordinates.latitude+','+location_coordinates.longitude+'&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY;
          $.ajax({
            url : url,
            type : "GET"
          }).done(function(return_data){
            // console.log(return_data);
            if(return_data.status == "OK" && return_data.results[0]){
              obj_this.setState({
                location : return_data.results[0].formatted_address
              });
              obj_this.context.loading(0);
            }else{
              obj_this.context.loading(0);
              alert("Couldn't fetch your location");
            }
          });
        }
      }
    });
    var overflowTimeout = setTimeout(()=>{});
    $(".products_container").on("click",".maximize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","none");
      elem.heightAuto().find(".minimize_button").css("display","block");
      $(this).css("display","none");
      overflowTimeout = setTimeout(function(){
        elem.css("overflow","");
      },1000);
    });
    $(".products_container").on("click",".minimize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","grid");
      clearTimeout(overflowTimeout);
      var original_height = elem.height();
      elem.height(original_height);
      setTimeout(function(){
      elem.css({
        "height" : "46px",
        "overflow" : "hidden"
        }).find(".maximize_button").css("display","block");
      },1);
      $(this).css("display","none");
    });

  }

  mobileNoChange(e){
    // console.log(e.target.value);
    $(".customer_suggestions_container").empty();
    this.setState({
      [e.target.name] : e.target.value
    });
    if(this.state.lead_id){
      return;
    }
    if(!e.target.value){
      return;
    }
    this.setState({
      customer_id : ''
    });
    this.context.loading(1);
    const obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/customerSearch`;
    customer_XHR.abort();
    customer_XHR = $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        mobile_no : e.target.value
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.customers.length){
        for(var i=0;i<return_data.customers.length;i++){
          var node = singleCustomer_DOM.clone();
          node.find(".customer_name").html(return_data.customers[i]['customer_name']);
          node.find(".customer_phone_no").html(return_data.customers[i]['customer_phone']);
          node.attr("customer_id",return_data.customers[i]['id']);
          $(".customer_suggestions_container").append(node);
        }
        $(".customer_suggestions_container").css("display","block");
        obj_this.context.loading(0);
      }else{
        $(".customer_suggestions_container").css("display","none");
        obj_this.context.loading(0);
      }
    });
    
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }
  onFollowupDateChange(value){
    // console.log(value);
    this.setState({followup_date : value});
  }

  enquiryFromChange(e){
    // console.log(option);
    if(e.target.value != 208){
      this.setState({
        influencer : {}
      });
    }
    this.setState({
      enquiry_from : e.target.value
    }); 
  }

  districtChange(option){
    this.setState({
      district : option
    });
  }

  statusChange(option){
    this.setState({
      enq_status : option,
      followup_date : tomorrow
    });
  }

  influencerChange(option){
    this.setState({
      influencer : option
    });
  }

  leadTypeChange(e){
    // console.log(e);
    this.setState({
      lead_type : e.target.value
    }); 
  }


  productChange(option,idx){
    // console.log(option);
    const rows = [...this.state.rows];
    // var item = rows.filter(item => item.product.label == option.label);
    // if(item.length){
    //   alert(item[0].product.label+" already added.");
    //   // rows.splice(-1,1);
    //   // this.setState({
    //   //     rows
    //   //   });
    //   return;
    // }

    const row = {...rows[idx]}
    row.specifications = '';
    row.qty = '';
    row.unit = '';
    row.product = option;
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/getProductUnits`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        product_id : option.value,
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        row.units = return_data.units;
        rows[idx] = row;
        obj_this.setState({
          rows
        });
        obj_this.context.loading(0)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  unitChange(option,idx){
    // console.log(option);
    if(!option.total){
      alert("Unit price is not added");
      return;
    }
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row.unit = option;
    row.unit_price = option.total;
    rows[idx] = row;
    this.setState({
      rows
    },() => {
      this.find_total(idx);
    });

  }

  find_total(idx){
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    const qty =row.qty;
    const unit_price = row.unit_price;
    if(!unit_price){
      return;
    }
    const total = qty*unit_price;
    row.total = parseFloat(total).toFixed(2);
    // console.log(total);
    rows[idx] = row;
    this.setState({
      rows
    });
    var grand_total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        grand_total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      grand_total : parseFloat(grand_total).toFixed(2)
    });
  }

  handleChange = idx => e => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row[name] = value;
    rows[idx] = row;
    var obj_this = this;
    this.setState({
      rows
    },() =>{
      if(e.target.name == "qty"){
        obj_this.find_total(idx)
      }
    });    
  };

  handleAddRow = () => {
    //products validation
    if(!this.validateProducts(this.state.rows)){
      return;
    }
    // console.log(this.state.rows);
    $(".single_product_container:not(:last-of-type) .minimize_button").trigger("click");
    const item = {
      product : {},
      specifications: "",
      qty: "",
      unit : {}
    };
    
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    // console.log(idx);
    // return;
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ 
      rows 
    });
    var grand_total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        grand_total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      grand_total : parseFloat(grand_total).toFixed(2)
    });

  }

  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    const lead = this.state;
    // return;
    if(!lead.district.value){
       this.districtSelect.current.focus();
      return;
    }
    if(!lead.enq_status.value){
       this.statusSelect.current.focus();
      return;
    }
    if(lead.enquiry_from == 208 && !lead.influencer.value){
       this.influencerSelect.current.focus();
      return;
    }
    //products validation
    if(lead.rows[0]['product']['value']){
      if(!this.validateProducts(lead.rows)){
        return;
      }
    }
    //product spec unique checking
    if(lead.rows.length>1){
      var exist = 0;
      for(var i=0;i<lead.rows.length;i++){
        var product = lead.rows[i]['product']['value'];
        var specification = lead.rows[i]['specifications'];
        for(var j=0;j<lead.rows.length;j++){
          if(i != j){
            if(lead.rows[j]['product']['value'] == product && lead.rows[j]['specifications'].toUpperCase() == specification.toUpperCase()){
              // $(".single_product_container").find(".minimize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").find(".maximize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").css('background-color','#ff000063');
              exist = 1;
              // break;
            }
          }
        }
      }
      if(exist){
        alert("Products with similar specification exists.");
        return false;
      }
    }
    lead.f_date = lead.enq_status.label == "FOLLOW UP"?lead.followup_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }):'';
    // console.log(lead);
    // return;
    const url = `${process.env.REACT_APP_SSS}/api/saveExeLead`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        lead : lead
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "insert"){
        alert("Enquiry saved");
      }else if(return_data.status == "update"){
        alert("Enquiry updated");
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
      obj_this.props.history.replace('/leads');
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }

  validateProducts(products){
    if(products.length == 1){
      if(!products[0].product.label){
        alert("Choose product");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product").focus();
        return false;
      }
      if(!products[0].qty){
        alert("Quantity required");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product_qty").focus();
        return false;
      }
      if(!products[0].unit.label){
        alert("Choose unit");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".unit").focus();
        return false;
      }
    }else{
      for(var i=0;i<products.length;i++){
        if(products[i].product.label){
          if(!products[i].qty){
            alert("Quantity required");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".product_qty").focus();
            return false;
          }
          if(!products[i].unit.label){
            alert("Choose unit");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".unit").focus();
            return false;
          }
        }
      }
    }
    return true;
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_lead_container">
          <div className="page_header">
            <Link className="back_button" to="/leads"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">{this.state.lead_id?"Edit":"New"} Lead</div>
          </div> 
          <form className="enquiry_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
              <div className="input_grid">
                <div className="customer_container">
                  <div className="input_label">Mobile Number</div>
                  <input className="mobile_no input custom_input"
                    type="tel"
                    name="mobile_no"
                    value={this.state.mobile_no}
                    onChange={e => this.mobileNoChange(e)}
                    pattern="^[0]?[1-9][0-9]{9}$"
                    required
                    autoComplete="off"
                  />
                  <div className="customer_suggestions_container">
                    <div className="single_customer_details">
                      <div className="customer_name"></div>
                      <div className="customer_phone_no"></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="input_label">Customer Name</div>
                  <input className="name input custom_input"
                    type="text"
                    name="customer_name"
                    value={this.state.customer_name}
                    onChange={this.formChange}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
{/*
              <div className="input_grid">
                <div>
                  <div className="input_label">Latitude</div>
                  <input className="mobile_no input custom_input"
                   type="text"
                    name="customer_name"
                    value={this.context.location.latitude}
                    onChange={this.formChange}
                    required
                    autoComplete="off"
                  />
                </div>
                <div>
                  <div className="input_label">Longitude</div>
                  <input className="name input custom_input"
                    type="text"
                    name="customer_name"
                    value={this.context.location.longitude}
                    onChange={this.formChange}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
*/}
              <div className="input_radio_container">
                <span>Type : </span>
                <div className="lead_type_radio_buttons">
                  {this.state.enq_types.map((item) => (
                    <div className="label_radio_container" key={item.id}>
                      <input type="radio" 
                        name="enquiry_from" 
                        value={item.id}
                        checked={this.state.enquiry_from == item.id} 
                        onChange={e => this.enquiryFromChange(e)}
                      />
                      <div>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="input_grid">
                <div>
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    // required
                  />
                </div>
                <div>
                  <div className="input_label">Location/Address</div>
                  <div className="location_container">
                    <textarea 
                      className="location input"
                      name = "location"
                      value = {this.state.location}
                      onChange = {this.formChange}
                      required
                    />
                    <div className="location_button">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                </div>

              </div>
              <div className="input_grid">
                <div>
                  <div className="input_label">Status</div>
                  <Select 
                    options={this.state.enq_statuses}  
                    value ={this.state.enq_status}
                    className="enq_status" 
                    ref={this.statusSelect}
                    onChange={e => this.statusChange(e)}
                    required
                  />
                </div>
                <div style={{visibility:this.state.enq_status.label == "FOLLOW UP"?"visible":"hidden"}}>
                  <div className="input_label">Followup Date</div>
                  <DatePicker
                    className="input custom_input followup_date"
                    onChange={(value) => this.onFollowupDateChange(value)}
                    value={this.state.followup_date}
                    format="dd-MM-y"
                    minDate = {new Date()}
                  />
                </div>
              </div>
              <div className="input_label">Influencer</div>
              <Select 
                options={this.state.influencers}  
                value ={this.state.influencer}
                className="district" 
                ref={this.influencerSelect}
                onChange={e => this.influencerChange(e)}
              />
              <div className="enquiry_type_radio_buttons">
                {this.state.lead_types.map((item) => (
                  <div className="label_radio_container" key={item.id}>
                    <input type="radio" 
                      name="lead_type" 
                      value={item.id}  
                      onChange={e => this.leadTypeChange(e)}
                      checked={this.state.lead_type == item.id} 
                    />
                    <div>{item.name}</div>
                  </div>
                  ))}
        
                </div>
              
            </div>

            <div className="products_container blocks">
              <div className="subtitle" 
                style={{display:"inline-block"}}
              >
                <i className="fa fa-shopping-cart"></i> 
                  &nbsp;Products
              </div>
              {this.state.rows.map((item, idx) => (
                <div className="single_product_container" key={idx} index={idx}>
                  <div className="product_label_and_delete_button">
                    <div className="maximize_button">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="minimize_button">
                      <i className="fa fa-minus"></i>
                    </div>
                    <div className="product_label">
                        {this.state.rows[idx].product.label}
                    </div>
                    <i className="delete_product_button fa fa-trash-alt" 
                      type="button"
                      style={{visibility:this.state.rows.length >1?"visible":"hidden"}}
                      onClick={this.handleRemoveSpecificRow(idx)}
                    ></i>
                  </div>
                  <div className="qty_unit_price_container">
                    <span
                       style={{visibility:this.state.rows[idx].unit.label?"visible":"hidden"}}
                    >Qty : {this.state.rows[idx].qty} {this.state.rows[idx].unit.label}</span>
                    <span
                       style={{visibility:this.state.rows[idx].unit_price?"visible":"hidden"}}
                    >Unit Price : {this.state.rows[idx].unit_price}</span>
                    <div 
                      style={{visibility:this.state.rows[idx].total?"visible":"hidden"}}
                      className="product_total"
                    >Price: Rs.
                      <span>{this.state.rows[idx].total}</span>
                    </div>
                  </div>
                  <div className="input_label">Product</div>
                  <Select 
                    options={this.state.products}  
                    value ={this.state.rows[idx].product}
                    className="product" 
                    onChange={e => this.productChange(e,idx)}
                  />
                  <div className="input_label">Specifications</div>
                  <textarea className="input specifications custom_input" 
                    name="specifications"
                    value={this.state.rows[idx].specifications}
                    onChange={this.handleChange(idx)}
                    autoComplete="off"
                  />
                 <div className="qty_unit_container">
                    <div>
                      <div className="input_label">Quantity</div>
                      <input className="product_qty custom_input"
                        type="number"
                        name="qty"
                        step="0.001"
                        min="0"
                        value={this.state.rows[idx].qty}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      <div className="input_label">Unit</div>
                      <Select 
                        options={this.state.rows[idx].units} 
                        value ={this.state.rows[idx].unit}
                        placeholder="" 
                        className="unit" 
                        onChange={e => this.unitChange(e,idx)}
                      />
                    </div>
                 </div>
              </div>
              ))}
              <div className="price_and_add_button_container">
                <div className="grand_total"
                  style={{visibility:this.state.grand_total?"visible":"hidden"}}
                >Total ({this.state.rows.length}) Rs.
                  <b> {this.state.grand_total}</b>
                  </div>
                <button className="add_product_button"
                  type="button"
                  onClick={this.handleAddRow}
                >
                  <i className="fa fa-plus"></i> Product
                </button>
              </div>
              <div className="input_label">Remarks</div>
              <textarea className="input remarks custom_input" 
                name="remarks"
                value={this.state.remarks}
                onChange={this.formChange}
                autoComplete="off"
                required = {this.state.enq_status.label == "LOST"?true:false}
              />
            </div>
            <button type="submit" className="submit_button"><i className="far fa-save"></i> Save</button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewLeads;
NewLeads.contextType = MyContext;
