import React, { Component } from "react";
import {Link} from "react-router-dom";

import './Home.scss';
import MyContext from '../MyContext.jsx';
import { CgList, CgUserAdd } from 'react-icons/cg';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';

const options1 = {
  maintainAspectRatio: false ,
  responsive: true,
  plugins: {
    legend: {
      display: false,       
    }
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      designation:'',
      category_targets:[],
      average_rate:'',
      total_outstanding:'',
      total_overdue:'',
      sales_order_day:'',
      sales_order_month:'',
      category:[],
      pending_order_day:'',
      pending_order_month:'',
      collection_day:'',
      collection_month:'',
      sales_percentage : 0,
      collection_percentage : 0,
      dispatch_day:'',
      dispatch_month:'',
    }
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(){    
    this.fetchData();
  }

  fetchData(){
    const url = `${process.env.REACT_APP_SSS}/api/getExecutiveDashboardDetails`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){

        var cat = return_data.category;

          for (var i=0;i<cat.length;i++){
            var list={};
            var month =cat[i].monthly_sale;
            var month_length=month.length;
            if(month_length>0){
              var res = month.reduce(function (acc, curr) {
                return {
                  total_qty: parseFloat(acc.total_qty) + parseFloat(curr.total_qty),
                }
              })

              list['name'] = cat[i].category_name;
              list['unit'] = cat[i].unit;
              list['qty'] =res;
              cat[i]['month']=list;
            
            }
             
            var day=cat[i].daily_sale;
            var length=day.length;
            if(length>0){
            var response = day.reduce(function (acc, curr) {
              return {
                total_qty: parseFloat(acc.total_qty) + parseFloat(curr.total_qty),
              }
            })

            list['unit'] = cat[i].unit;
            list['daily_qty'] =response;
            cat[i]['day']=list;

            }

          }
          //console.log(cat);


        obj_this.setState({
          designation: return_data.designation_name,
          category_targets:return_data.category_targets,
          average_rate:return_data.average_rate,  
          total_outstanding:return_data.total_outstanding,
          total_overdue:return_data.total_overdue,
          sales_order_day:return_data.so_day,
          sales_order_month:return_data.so_month,
          category:return_data.category,
          pending_order_day:return_data.pending_order_day,
          pending_order_month:return_data.pending_order_month,
          collection_day:return_data.collection_day,
          collection_month:return_data.collection_month,
          dispatch_day:return_data.dispatch_day,
          dispatch_month:return_data.dispatch_month,
        })
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
    });
   console.log(this.state.category_month);
    // ui.swipeMenu(".home_dashboard_container");
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div>
          <div className="home_container">
            <div className="header_spacer"></div>
            <div className="home_dashboard_container uijs swipemenu">
              <div className="list_blocks_container">
                <InfiniteScroll
                  dataLength={ this.state.category_targets.length }
                  refreshFunction={this.fetchData}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={200}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' , top : '50px'}}>&#8595; Pull down to refresh</h3>
                  }
                  releaseToRefreshContent={
                    <div className="loading_container" style={{ textAlign: 'center' , top : '100px'}} >
                      <div className="loading_symbol">
                        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                        width="40px" height="40px" viewBox="0 0 50 50" style={{enableBackground:'new 0 0 50 50'}}>
                          <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                              {/*<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite"/>*/}
                          </path>
                        </svg>
                      </div>
                    </div>
                  }
                  >

                  <div className="lists_container graph_block">
                    <div className="yellow_shade11">
                      <div className="desigtn_block">{this.state.designation}</div>
                    </div>
                    <div className="yellow_shade1">
                      <div className="current_month">{new Date().toLocaleString('default', { month: 'long' })} { new Date().getFullYear()}</div>
                    </div>
                    <div className="yellow_shade2">
                      <div className="yellow_shade3"></div>
                      <div className="sale_graph_container">
                        <div style={{padding:"5px"}}>
                          <table className="table_list">
                            <thead>
                              <tr>
                                    <th valign="top" align="center" >Category </th>
                                    <th valign="top" align="center" >Target</th>
                                    <th valign="top" align="center" >Achieved</th>
                                    <th valign="top" align="center" >Pending</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{display:this.state.category_targets.length?"none":"table-row"}}>
                                <td colSpan="4" align="center">No targets!</td>
                              </tr>
                              {this.state.category_targets.map((item,index) => (
                                <tr className="tr" key={index} >
                                
                                  <td valign="bottom" align="center" >{item.category_name}</td>
                                  <td valign="bottom" align="center" >{item.target} {item.amount_quantity =='Q' ? item.unit : <i className="fas fa-rupee-sign"></i>}</td>
                                  <td valign="bottom" align="center" >{item.acheived_target} {item.amount_quantity =='Q' ? item.unit : <i className="fas fa-rupee-sign"></i>} <br/>
                                  <span>({item.target >0 ? parseFloat(item.acheived_target*100/parseFloat(item.target).toFixed(2)).toFixed(2):0}%)</span></td>
                                  <td valign="bottom" align="center" >{item.pending_target} {item.unit}<br/>
                                  <span>({item.target >0 ? parseFloat(item.pending_target*100/parseFloat(item.target).toFixed(2)).toFixed(2):0}%)</span></td>
                                </tr>
                              ))} 
                            
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {/*<div style={{paddingTop:"15px"}} >
                    <div className="yellow_shade2">
                      <div className="sale_graph_container">
                        <div className="average_rate" >
                        <div>Average Rate</div>
                        <div ><i className="fas fa-rupee-sign"></i> {this.state.average_rate}</div>
                        </div>
                      </div>
                    </div>
                    </div>*/}
                  

                    <div style={{paddingTop:"15px"}} >
                      <div className="yellow_shade2">
                        <div className="sale_graph_container">
                          <div style={{padding:"5px"}}>
                            <table className="table_list3">
                              <tbody>
                                <tr>
                                  <td align="left">Total Outstanding</td>
                                  <td><span>{this.state.total_outstanding}</span></td>
                                </tr>    
                                <tr>
                                  <td align="left">Total Overdue</td>
                                  <td><span>{this.state.total_overdue}</span></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
            
                    <div style={{paddingTop:"20px"}}>
                      <div className="yellow_shade2">
                        <div className="sale_graph_container">
                          <div style={{padding:"5px"}}>
                            <table className="table_list2">
                              <thead>
                                <tr>
                                  <th valign="top" align="center" ></th>
                                  <th valign="top" align="center" >Day</th>
                                  <th valign="top" align="center" >Month</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td valign="top" ><span>Sales Order</span></td>
                                  <td valign="bottom" align="center"><span>{this.state.sales_order_day} Nos</span></td>
                                  <td valign="bottom" align="center"><span>{this.state.sales_order_month} Nos</span></td>
                                </tr>
                                {this.state.category.map((item,index) => (
                                <tr  key={index}>
                                  <td valign="top" >{item.category_name}</td>
                                  <td valign="bottom" align="center">
                                    {item.day?<span>{parseFloat(item.day.daily_qty.total_qty).toFixed(3)} {item.day.unit}</span>:<span>-</span>}</td>
                                  <td valign="bottom" align="center">
                                    {item.month?<span>{parseFloat(item.month.qty.total_qty).toFixed(3)} {item.month.unit}</span>:<span>-</span>}</td>
                                </tr> ))}   
                                <tr>
                                  <td valign="top" ><span>Dispatch Orders</span></td>
                                  <td valign="bottom" align="center"><span>{this.state.dispatch_day}</span> Nos</td>
                                  <td valign="bottom" align="center"><span>{this.state.dispatch_month} Nos</span></td>
                                </tr>                 
                                <tr>
                                  <td valign="top" ><span>Pending Order</span></td>
                                  <td valign="bottom" align="center"><span>{this.state.pending_order_day} Nos</span></td>
                                  <td valign="bottom" align="center"><span>{this.state.pending_order_month} Nos</span></td>
                                </tr>
                                <tr>
                                  <td valign="top" ><span>Collection</span></td>
                                  <td valign="bottom" align="center"><span><i className="fas fa-rupee-sign"></i> {this.state.collection_day}</span></td>
                                  <td valign="bottom" align="center"><span><i className="fas fa-rupee-sign"></i> {this.state.collection_month}</span></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </InfiniteScroll>

              </div>

              <div>
                  <Link to='/pricelist'>
                    <button className="pushable">
                      <span className="front">
                        Price List <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                      </span>
                    </button>
                  </Link>
              </div>

             
            </div>
            <div className="bottom_navbar_spacer"></div>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Home;
Home.contextType = MyContext;
