import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './index.css';
import App from './App.jsx';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
import './assets/js/jquery_mods.js';
import './assets/js/ui.js';
import './assets/js/geo.js';
import './assets/scss/ui.scss';

const renderReactDom = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

if (window.cordova) {
  window.project_path = "/android_asset/www";
  document.addEventListener('deviceready', () => {
    const messaging = cordova.plugins.firebase.messaging;
    // messaging.requestPermission()
    //   .then(() => messaging.getToken())
    //   .then((token) => {
    //     console.log('Firebase Token:', token);
    //     alert(token)
    //     // You can save this token on your backend (e.g., Laravel backend)
    //     // associating it with the user/device to send targeted notifications.
    //   })
    //   .catch((error) => {
    //     console.error('Error getting Firebase token:', error);
    //   });

    // Handle incoming message when the app is in the foreground
    messaging.onMessage((message) => {
      console.log('Received FCM message:', message);
    });

    // Handle incoming message when the app is in the background or killed
    messaging.onBackgroundMessage((message) => {
      console.log('Received FCM background message:', message);
    });

    renderReactDom();
  }, false);
} else {
  window.project_path = "";
  renderReactDom();
}
reportWebVitals();