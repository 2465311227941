import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './DealerWiseReport.scss';

class DealerWiseReport extends Component{
  constructor(props){
    super(props);
    this.zoneSelect = React.createRef();
    // this.districtSelect = React.createRef();

    this.state = {
      zones    :   [],
      zone :   {},
      // districts    :   [],
      // district :   {},
      categories    :   [],
      category :   {},
      customers : [],
      showing_customers:[],
      search:'',
      from_date : new Date(),
      to_date : new Date(),
      minDate : new Date(new Date().getFullYear()-1, new Date().getMonth(), 1 ),
      maxDate : new Date(new Date().getFullYear(), new Date().getMonth()+1, 0),
    }
    this.zoneChange = this.zoneChange.bind(this);
    // this.districtChange = this.districtChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/mappedZones`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         zones : return_data.zone_mapped,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
   }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
        obj_this.props.history.replace("/");
    });
    // const url = `${process.env.REACT_APP_SSS}/api/get/all/districts`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url,
    //   data : {
    //     sid : this.context.user_details.sid
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   console.log(return_data);
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      districts : return_data.districts,
    //     });
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //     obj_this.context.loading(0);
    //     alert("Network error");
    // });

    const url3 = `${process.env.REACT_APP_SSS}/api/get/productCategories`;
    this.context.loading(1);
    $.ajax({
      url : url3,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         categories : return_data.categories,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });

    // var obj_this = this;
    // const url2 = `${process.env.REACT_APP_SSS}/api/reports/getDealerWiseReport`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url2,
    //   data : {
    //     sid : this.context.user_details.sid,
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   console.log(return_data);
    //   // customers = customers.sort(function(a,b){
    //   //   return a.no_due - b.no_due
    //   // });
    //   // return;
      
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      customers : return_data.customers,
    //      showing_customers:return_data.customers
    //     });
    //     // debugger;
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //   alert("Network error");
    //   obj_this.context.loading(0);

    // });

    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_schedule =[];
    if(e.target.value){
      showing_schedule = this.state.showing_customers.filter((item) => {return item.name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_schedule = this.state.customers;
    }
    // showing_customers = showing_customers.length?showing_customers:this.state.doc_name;
    this.setState({
      showing_customers : showing_schedule
    });
  }

  zoneChange(option){
    this.setState({
      zone : option
    });
  }

  // districtChange(option){
  //   this.setState({
  //     district : option
  //   });
  // }
  categoryChange(option){
    this.setState({
      category : option
    });
  }  

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.district.value){
    //   this.districtSelect.current.focus();
    //   return;
    // }
    
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/reports/all/getDealerWiseReport`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        zone_id : this.state.zone.value,
        // district_id : this.state.district.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA'),
        category_id : this.state.category.value
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // customers = customers.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.customers,
         showing_customers:return_data.customers
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
   
    
  }


  fromDateChange(value){
    // console.log(value);
    this.setState({from_date : value});
  }
  toDateChange(value){
    // console.log(value);
    this.setState({to_date : value});
  }



  
    

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="dealer_wise_report_page_container">
          <div className="page_header">
            <Link className="back_button" to="/reports"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Dealer Wise Report</div>
          </div> 
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
              <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      minDate = {this.state.minDate}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      maxDate = {this.state.maxDate}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Zones</div>
                  <Select 
                    options={this.state.zones}  
                    value ={this.state.zone}
                    className="district" 
                    ref={this.zoneSelect}
                    onChange={e => this.zoneChange(e)}
                    required
                  />
                  {/* <div className="input_label">Districts</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    required
                  /> */}
                  <div className="input_label">Product Category</div>
                  <Select 
                    options={this.state.categories}  
                    value ={this.state.category}
                    className="category" 
                    // ref={this.districtSelect}
                    onChange={e => this.categoryChange(e)}
                    // required
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
                </div>
                
            </div>
          </form>
          <div className="search_container">
            <CgSearch/>
            <input className="customer_search_input" 
              placeholder="Search Customers.."
              onChange={this.formChange}
              value={this.search}
            />
          </div>
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{width:"40%"}}>Customer</th>
                  <th align="center">Qty </th>
                  <th align="center">Total </th>

                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_customers.length?"none":"table-row"}}>
                <td colSpan="14" align="center">No result!</td>
              </tr>
              {this.state.showing_customers && this.state.showing_customers.map((cust,index) => (
                <tr key={index}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.name}</div>
                  </td>
                  <td>
                  {
                  Object.entries(cust.dealer_wise_report).map(([key,value])=>{
                    return (<div className="bills_table_container"  key={index+key} >
                    <table className="bills_table">
                      <thead>
                        <tr>
                          <th>{key} - {parseFloat(value).toFixed(2)}</th>
                        </tr>
                      </thead>
                      
                      {/* <tbody>
                        {dealer_wise_report && dealer_wise_report.map((bill,idx) => (
                        <tr key={idx}>
                          <td>{bill.category_name}</td>
                          <td align="right" style={{textAlign:"right"}}>
                            <span>{bill.cat_total}</span>
                            <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                          </td>
                          <td style={{border:"none",visibility:bill.pending_amount>0?"visible":"hidden",textAlign:"right"}} align="right">
                            <span >{bill.pending_amount}</span>
                            <span>({bill.due_days} Days)</span>
                          </td>
                        </tr>
                        ))}
                      </tbody> */}
                    </table>
                  </div>
                    );

                  })
                  }
                  </td>
                  <td valign="top"  align="center">{cust.total_sale}</td>
                    
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default DealerWiseReport;
DealerWiseReport.contextType = MyContext;
