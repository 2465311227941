
import {Link} from "react-router-dom";

import './Dashboard.scss';

import { CgList, CgUserAdd } from 'react-icons/cg';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import React, { useContext } from 'react';
import MyContext from '../MyContext.jsx';


const Dashboard = () => {
  const userDetails = useContext(MyContext);

  return (
    <div className="dashboard_page_container">
      <div className="menu_container">
       

        <Link to="/outstandings" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/outstanding.png"} height="100px;" />
        </Link>

        
        <Link to="/reports" className="single_menu_container"  style={{
         display:userDetails.user_details.dealer_report?"grid":
         userDetails.user_details.exe_report?"grid":
         userDetails.user_details.so_report?"grid":
         userDetails.user_details.category_sale_report?"grid":"none"}}
       >
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/reports.png"} height="100px;" />
        </Link>
        

      </div>
    </div>
     
  )
}

export default Dashboard;

