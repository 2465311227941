import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";

import './PriceList.scss';

class PriceList extends Component{
  constructor(props){
    super(props);
    this.state = {
      products:[],
      showing_products:[]
    }
    this.formChange = this.formChange.bind(this);
  }
  componentDidMount(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/getProductList`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
     
      if(return_data.status == "success"){
        var active_products = return_data.active_products;
        obj_this.setState({
         products : active_products,
         showing_products : active_products
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  }

  formChange(e){
    var showing_products =[];
    if(e.target.value){
      showing_products = this.state.products.filter((item) => {return item.name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_products = this.state.products;
    }
    this.setState({
      showing_products : showing_products
    });
  }

  
  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="price_list_page_container">
          <div className="page_header">
            <Link className="back_button" to="/home"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Price List</div>
          </div> 
          <div className="search_container">
            <CgSearch/>
            <input className="product_search_input" 
              placeholder="Search product.."
              onChange={this.formChange}
            />
          </div>
          <div className="table_container">
            <table className="products_price_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{maxWidth:"40px"}}>Product</th>
                  <th style={{maxWidth:"40px"}} align="center">Unit</th>
                  <th style={{minWidth:"80px"}} align="center">Rate</th>
                  <th style={{maxWidth:"40px"}} align="center">Last Updated Time</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_products.length?"none":"table-row"}}>
                <td colSpan="5" align="center">No result!</td>
              </tr>
             {this.state.showing_products&&this.state.showing_products.map((cust,index) => (
                <tr key={index} >

                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top">{cust.name}</td>  
                  {cust.price?
                  <td valign="top">{cust.price.unit}</td>:<td>-</td>}
                  {cust.price?
                  <td valign="top"><span><i class="fas fa-rupee-sign"></i>&nbsp;{cust.price.total}</span></td>:<td>-</td>}
                  {cust.price?
                  <td valign="top">{new Date(cust.price.time).toLocaleString('en-GB')}</td>:<td>-</td>}
                  
              </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default PriceList;
PriceList.contextType = MyContext;
