import React, { Component } from "react";
// import { render } from "react-dom";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";

import './NewOrder.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class NewOrder extends Component {
  constructor(props) {
    super(props);
    this.locationSelect = React.createRef();

    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      salesorder_id : '',
      customers : [],
      customer : {},
      order_date : today,
      delivery_date : tomorrow,
      file :'',
      delivery_types : [],
      delivery_type : 256,
      payment_types : [],
      payment_type : 132,
      remarks : '',
      billing_address : '',
      billing_contact_no : '',
      delivery_address : '',
      clone_address : false,
      delivery_contact_no : '',
      locations : [],
      delivery_location : {},
      products : [],
      rows: [{
        product : {},
        specifications : '',
        qty : '',
        unit_price : '',
        units : [],
        unit: {},
        total : '',
        selling_price : '',
        base_price :"",
        final_selling_price : ""
      }],
      grand_total : ''
    }
    this.formChange = this.formChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.paymentTypeChange = this.paymentTypeChange.bind(this);
    this.deliveryTypeChange = this.deliveryTypeChange.bind(this);
  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    const {pathname} = this.props.location;
  
      const salesorder_id = this.props.match.params.sid;
      const url = `${process.env.REACT_APP_SSS}/api/editSalesorder`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          salesorder_id : salesorder_id
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var state_object = return_data.state_object;
          state_object.order_date = epochToDate(state_object.order_date);
          state_object.delivery_date = epochToDate(state_object.delivery_date);
          state_object.products = state_object.products.filter((item) => item.units.length);
          state_object.clone_address = false;
          obj_this.setState(state_object,() => {
            $(".minimize_button").trigger("click");
            $(".new_salesorder_container").css("visibility","visible");
          });

          obj_this.context.loading(0);
          console.log(obj_this.state);

        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

    var overflowTimeout = setTimeout(()=>{});
    $(".products_container").on("click",".maximize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","none");
      elem.heightAuto().find(".minimize_button").css("display","block");
      $(this).css("display","none");
      overflowTimeout = setTimeout(function(){
        elem.css("overflow","");
      },1000);
    });

    $(".products_container").on("click",".minimize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","grid");
      clearTimeout(overflowTimeout);
      var original_height = elem.height();
      elem.height(original_height);
      setTimeout(function(){
      elem.css({
        "height" : "46px",
        "overflow" : "hidden"
        }).find(".maximize_button").css("display","block");
      },1);
      $(this).css("display","none");
    });
  }

  customerChange(option){
    // console.log(option);
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/getCustomerDetails`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        customer_id : option.value,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          customer : option,
          billing_address : return_data.customer.address,
          billing_contact_no : return_data.customer.tel_o??'',
          delivery_address : '',
          delivery_contact_no : '',
          delivery_location : return_data.delivery_location
        }); 
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  paymentTypeChange(e){
    this.setState({
      payment_type : e.target.value
    }); 
  }

  deliveryTypeChange(e){
    this.setState({
      delivery_type : e.target.value
    }); 
  }

  onCheckboxChange(){
    if(this.state.clone_address){
      this.setState({
        delivery_contact_no : this.state.billing_contact_no,
        delivery_address : this.state.billing_address,
      });
    }
    this.setState({
      clone_address : !this.state.clone_address
    });
  }
  
  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }
  onOrderDateChange(value){
    // console.log(value);
    this.setState({order_date : value});
  }

  onDeliveryDateChange(value){
    // alert(value);
    this.setState({delivery_date : value});
  }

  locationChange(option){
    this.setState({delivery_location : option})
  }

  productChange(option,idx){
    // console.log(option);
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    // var item = rows.filter(item => item.product.label == option.label);
    // if(item.length){
    //   alert(item[0].product.label+" already added.");
    //   // rows.splice(-1,1);
    //   // this.setState({
    //   //     rows
    //   //   });
    //   return;
    // }

    const row = {...rows[idx]}
    row.specifications = '';
    row.unit_price = '';
    row.qty = '';
    row.unit = {};
    row.product = {};
    row.final_selling_price = '';
    row.base_price = ''
    row.selling_price = {}
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/getProductUnits`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        product_id : option.value,
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
          console.log(return_data);
          if(return_data.selling_price && return_data.selling_price.total ==null){
            alert('Please set Selling Price in Product Master');
            obj_this.context.loading(0)
            return;
          }
          row.units = return_data.units;
          row.selling_price = return_data.selling_price;
          row.final_selling_price = return_data.selling_price? return_data.selling_price.total !=null ?  return_data.selling_price.total :0 :0; 
          row.base_price = return_data.base_price;
          rows[idx] = row;
          row.product = option;
        
        obj_this.setState({
          rows
        });
        obj_this.context.loading(0)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  unitChange(option,idx){
    // console.log(option);
    $(".single_product_container").css('background-color','');
    // if(!option.total){
    //   alert("Unit price is not added");
    //   return;
    // }
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row.unit = option;
    row.unit_price = option.total;
    rows[idx] = row;
    this.setState({
      rows
    },() => {
      this.find_total(idx);
    });

  }

  find_total(idx){
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    const qty = row.qty;
    const unit = row.unit;
    const final_selling_price = row.final_selling_price;
    if(!final_selling_price){
      return;
    }
    const base_price = row.base_price;
    const base_qty = row.selling_price.base_qty;
    const base_unit = row.selling_price.unit_id;

    var price = 0; var final_qty = 0; var unit_price = row.unit_price;
    const price_in_base = final_selling_price/base_qty;


    if(unit.value != base_price.unit_id){
      // convert requested unit quantity to base unit quantity
      final_qty = qty * unit.base_qty
      price = final_qty *price_in_base
      unit_price = price/qty

    }else{
      price = qty* price_in_base
      unit_price = price/qty
    }
    // console.log(final_selling_price,price_in_base, final_qty, price);  
    
    row.unit_price = Math.round(unit_price*100)/100;

    row.total = Math.round(price*100)/100;
    // console.log(total);
    rows[idx] = row;
    this.setState({
      rows
    });
    var grand_total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        grand_total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      grand_total : parseFloat(grand_total).toFixed(2)
    });
  }

  handleChange = idx => e => {
    $(".single_product_container").css('background-color','');
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row[name] = value;
    rows[idx] = row;
    var obj_this = this;
    this.setState({
      rows
    },() =>{
      if(e.target.name == "qty" || e.target.name == "final_selling_price" ){
        obj_this.find_total(idx)
      }
    });    
  };

  handleAddRow = () => {
    $(".single_product_container").css('background-color','');
    if(!this.validateProducts(this.state.rows)){
      return;
    }
    // console.log(this.state.rows);
    $(".single_product_container:not(:last-of-type) .minimize_button").trigger("click");
    const item = {
      product : {},
      specifications: "",
      unit_price : '',
      qty: "",
      unit : {}
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    // console.log(idx);
    // return;
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ 
      rows 
    });
    var grand_total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        grand_total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      grand_total : parseFloat(grand_total).toFixed(2)
    });

  }

  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    if(!this.state.customer.value){
       this.refs.focus();
      return;
    }
    const salesorder = this.state;
   
    console.log(this.state);
    if(!salesorder.delivery_location.value){
      this.locationSelect.current.focus();
     return;
   }
    //products validation
    if(!this.validateProducts(salesorder.rows)){
      return;
    }
    
    //product spec unique checking
    if(salesorder.rows.length>1){
      var exist = 0;
      for(var i=0;i<salesorder.rows.length;i++){
        var product = salesorder.rows[i]['product']['value'];
        var specification = salesorder.rows[i]['specifications']?salesorder.rows[i]['specifications'].toUpperCase():"";
        for(var j=0;j<salesorder.rows.length;j++){
          if(i != j){
            var spec = salesorder.rows[j]['specifications']?salesorder.rows[j]['specifications'].toUpperCase():"";
            if(salesorder.rows[j]['product']['value'] == product && spec == specification){
              // $(".single_product_container").find(".minimize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").find(".maximize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").css('background-color','#ff000063');
              exist = 1;
              // break;
            }
          }
        }
      }
      if(exist){
        alert("Products with similar specification exists.");
        return false;
      }
    }
    salesorder.o_date = salesorder.order_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    salesorder.d_date = salesorder.delivery_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    if(salesorder.clone_address){
      salesorder.delivery_address = salesorder.billing_address;
      salesorder.delivery_contact_no = salesorder.billing_contact_no;
    }
    delete salesorder.locations;
    delete salesorder.customers;
    delete salesorder.products;

    const url = `${process.env.REACT_APP_SSS}/api/saveSalesorder`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        salesorder : salesorder
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "insert"){
        alert("Salesorder successfully inserted");
      }else if(return_data.status == "update"){
        alert("Salesorder successfully updated");
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
      obj_this.props.history.replace('/salesorders');
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }


  validateProducts(products){
    if(products.length == 1){
      if(!products[0].product.label){
        alert("Choose product");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product").focus();
        return false;
      }
      if(!products[0].qty){
        alert("Quantity required");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product_qty").focus();
        return false;
      }
      if(!products[0].final_selling_price){
        alert("Selling price required");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".selling_price").focus();
        return false;
      }
      if(!products[0].unit.label){
        alert("Choose unit");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".unit").focus();
        return false;
      }
    }else{
      for(var i=0;i<products.length;i++){
        if(products[i].product.label){
          if(!products[i].unit.label){
            alert("Choose unit");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".unit").focus();
            return false;
          }
          if(!products[i].qty){
            alert("Quantity required");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".product_qty").focus();
            return false;
          }
          if(!products[i].final_selling_price){
            alert("Selling price required");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".selling_price").focus();
            return false;
          }

        }
      }
    }
    return true;
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_salesorder_container">
          <div className="page_header">
            <Link className="back_button" to="/salesorders"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">{this.state.salesorder_id?"Edit":"New"} Salesorder</div>
          </div> 
          <form className="sales_order_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
              <div className="input_label">Customer</div>
              <Select 
                options={this.state.customers}  
                value ={this.state.customer}
                className="customer" 
                ref={r => {
                  this.refs = r;
                }}
                onChange={e => this.customerChange(e)}
                required
                isDisabled = {this.state.salesorder_id?true:""}
              />
              <div className="input_label">Order Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onOrderDateChange(value)}
                value={this.state.order_date}
                required
                format="dd-MM-y"
                minDate = {this.state.salesorder_id?"":new Date()}
              />
              <div className="input_label">Delivery Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onDeliveryDateChange(value)}
                value={this.state.delivery_date}
                format="dd-MM-y"
                minDate = {this.state.salesorder_id?"":new Date()}
              />
              <div className="input_radio_container">
                <span>Payment Type : </span>
                <div className="radio_buttons">
                  {this.state.payment_types.map((item) => (
                    <div className="label_radio_container" key={item.id}>
                      <input type="radio" 
                        name="payment_type" 
                        value={item.id}
                        checked={this.state.payment_type == item.id} 
                        onChange={e => this.paymentTypeChange(e)}
                      />
                      <div className="radio_label">&nbsp;{item.name}</div>&nbsp;&nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="input_radio_container">
                <span>Delivery Type : </span>
                <div className="radio_buttons">
                  {this.state.delivery_types.map((item) => (
                    <div className="label_radio_container" key={item.id}>
                      <input type="radio" 
                        name="delivery_type" 
                        value={item.id}
                        checked={this.state.delivery_type == item.id} 
                        onChange={e => this.deliveryTypeChange(e)}
                      />
                      <div className="radio_label">&nbsp;{item.name}</div>&nbsp;&nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="input_label">Remarks</div>
              <textarea className="input remarks custom_input" 
                name="remarks"
                value={this.state.remarks}
                onChange={this.formChange}
                autoComplete="off"
              />
            </div>
            <div className="billing_details_container blocks">
              <div className="subtitle">Billing</div>
              <div className="input_label">Address</div>
              <textarea className="input billing_address custom_input" 
                name="billing_address"
                value={this.state.billing_address}
                onChange={this.formChange}
                required
                autoComplete="off"
              />
              <div className="input_label">Contact No</div>
              <input className="billing_contact_no input custom_input"
                type="tel"
                name="billing_contact_no"
                value={this.state.billing_contact_no}
                onChange={this.formChange}
                pattern="^[0]?[1-9][0-9]{9}$"
                required
                autoComplete="off"
              />
            </div>
            <div className="delivery_details_container blocks">
              <div className="delivery_title_container">
                <div className="subtitle">Delivery</div>
                <span className="same_as_label">Same as billing</span>
                <input className="clone_address"
                  type="checkbox"
                  name = "clone_address"
                  checked = {this.state.clone_address}
                  onChange={this.onCheckboxChange}
                />
              </div>
              <div className="input_label">Address</div>
              <textarea className="input delivery_address custom_input" 
                name={this.state.clone_address?"billing_address":"delivery_address"}
                value={this.state.clone_address?this.state.billing_address : this.state.delivery_address }
                onChange={this.formChange}
                required
                autoComplete="off"
              />
              <div className="input_label">Contact No</div>
              <input className="delivery_contact_no input custom_input"
                type="tel"
                name={this.state.clone_address?"billing_contact_no":"delivery_contact_no"}
                value={this.state.clone_address?this.state.billing_contact_no :this.state.delivery_contact_no}
                onChange={this.formChange}
                pattern="^[0]?[1-9][0-9]{9}$"
                required
                autoComplete="off"
              />
              <div className="input_label">Location</div>
              <Select 
                options={this.state.locations}  
                value ={this.state.delivery_location}
                className="location" 
                ref={this.locationSelect}
                onChange={e => this.locationChange(e)}
                required
              />
            </div>
            <div className="products_container blocks">
              <div className="subtitle" 
                style={{display:"inline-block"}}
              >
                <i className="fa fa-shopping-cart"></i> 
                 Products
              </div>
              {this.state.rows.map((item, idx) => (
                <div className="single_product_container" key={idx} index={idx}>
                  <div className="product_label_and_delete_button">
                    <div className="maximize_button">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="minimize_button">
                      <i className="fa fa-minus"></i>
                    </div>
                    <div className="product_label">
                        {this.state.rows[idx].product.label}
                    </div>
                    <i className="delete_product_button fa fa-trash-alt" 
                      type="button"
                      style={{visibility:this.state.rows.length >1?"visible":"hidden"}}
                      onClick={this.handleRemoveSpecificRow(idx)}
                    ></i>
                  </div>
                  <div className="qty_unit_price_container">
                    <span
                       style={{visibility:this.state.rows[idx].unit.label?"visible":"hidden"}}
                    >Qty : {this.state.rows[idx].qty} {this.state.rows[idx].unit.label}</span>
                    <span
                       style={{visibility:this.state.rows[idx].unit_price?"visible":"hidden"}}
                    >Unit Price : {this.state.rows[idx].unit_price}</span>
                    <div 
                      style={{visibility:this.state.rows[idx].total?"visible":"hidden"}}
                      className="product_total"
                    >Price: Rs.
                      {/*<span>{parseFloat(this.state.rows[idx].qty*this.state.rows[idx].unit_price).toFixed(2)}</span>*/}
                      <span>{this.state.rows[idx].total}</span>
                    </div>
                  </div>
                  <div className="input_label">Product</div>
                  <Select 
                    options={this.state.products}  
                    value ={this.state.rows[idx].product}
                    className="product" 
                    onChange={e => this.productChange(e,idx)}
                    required
                  />

                  <div className="input_grid">
                    <div>
                      <div className="input_label">Specifications</div>
                      <input className="input specifications custom_input" 
                        type="text"
                        name="specifications"
                        value={this.state.rows[idx].specifications}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      <div className="input_label">Unit</div>
                      <Select 
                        options={this.state.rows[idx].units} 
                        value ={this.state.rows[idx].unit}
                        placeholder="" 
                        className="unit" 
                        onChange={e => this.unitChange(e,idx)}
                        required
                      />
                    </div>
                  </div> 
                  
                  <div className="input_grid">
                    <div>
                      <div className="input_label">Quantity</div>
                      <input className="product_qty custom_input"
                        type="number"
                        name="qty"
                        step="0.001"
                        min="0"
                        value={this.state.rows[idx].qty}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                   
                    </div>
                 </div>
                 <div className="">
                  <div className="input_label">
                    Recommended Price {this.state.rows[idx].selling_price?this.state.rows[idx].selling_price['label'] ? '- Rs '+this.state.rows[idx].selling_price['total'] + '/'+ this.state.rows[idx].selling_price['label']  :'':''}</div>
                  </div>
                 <div className="input_grid">
                  
                  
                    <div className="input_label ">Selling Price <span className="sp_label"> {this.state.rows[idx].selling_price?this.state.rows[idx].selling_price['label'] ? '- '+ this.state.rows[idx].selling_price['label'] :'':''}</span></div>
                      <input className="selling_price input custom_input"
                        type="number"
                        name="final_selling_price"
                        value={this.state.rows[idx].final_selling_price}
                        onChange={this.handleChange(idx)}
                        min="0"
                        step="0.01"
                        autoComplete="off"
                      />
                    </div>
              </div>
              ))}
              <div className="price_and_add_button_container">
                <div className="grand_total"
                  style={{visibility:this.state.grand_total?"visible":"hidden"}}
                >Total ({this.state.rows.length}) Rs.
                  <b> {this.state.grand_total}</b>
                  </div>
                <button className="add_product_button"
                  type="button"
                  style={{visibility:this.state.rows[this.state.rows.length-1].product.label && this.state.rows[this.state.rows.length-1].unit.label?"visible":"hidden"}}
                  onClick={this.handleAddRow}
                >
                  <i className="fa fa-plus"></i> Product
                </button>
              </div>
            </div>
            <div className="delivery_details_container blocks"
            style={{display:this.state.file != null && this.state.file != '' ?"block":"none"}}

            >
              <div className="delivery_title_container">
                <div className="subtitle">File Upload</div>
              </div>
              <div className="input_label">{this.state.file}</div>
            </div>
            <button type="submit" className="submit_button"><i className="far fa-save"></i> Save</button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewOrder;
NewOrder.contextType = MyContext;
