import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";


import './ExecutiveTargetReport.scss';

class ExecutiveTargetReport extends Component{
  constructor(props){
    super(props);
    this.districtSelect = React.createRef();
    this.executiveSelect = React.createRef();


    this.state = {
      executives :  [],
      executive :  {},
      customers : [],
      showing_customers:[],
      search_customer:'',
      monthYear:new Date(),

    }
    this.districtChange = this.districtChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.getTargetReport = this.getTargetReport.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    const url3 = `${process.env.REACT_APP_SSS}/api/getOutstandingExecutiveDuration`;
    this.context.loading(1);
    $.ajax({
      url : url3,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
   
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/reports/all/getSalesOrderreport`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
      
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.customers,
         showing_customers:return_data.customers
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);

    });

    // const url3 = `${process.env.REACT_APP_SSS}/api/get/all/executives`;
    // this.context.loading(1);
    // $.ajax({
    //   url : url3,
    //   data : {
    //     sid : this.context.user_details.sid
    //   },
    //   type : "POST"
    // }).done(function(return_data){
    //   // console.log(return_data);
    //   if(return_data.status == "success"){
    //     obj_this.setState({
    //      executives : return_data.executives,
    //     });
    //     obj_this.context.loading(0);
    //   }else{
    //     obj_this.context.loading(0);
    //     obj_this.props.history.replace("/");
    //   }
    // }).fail(function(){
    //     obj_this.context.loading(0);
    //     alert("Network error");
    // });

    // this.getTargetReport();

    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_schedule =[];
    if(e.target.name =="customer"){
      if(e.target.value){
        showing_schedule = this.state.showing_customers.filter((item) => {return item.label?item.label.toLowerCase().includes(e.target.value.toLowerCase()):''});
      }
      else{
        showing_schedule = this.state.customers;
      }
    }
    // showing_customers = showing_customers.length?showing_customers:this.state.doc_name;
    this.setState({
      showing_customers : showing_schedule
    });
  }

  districtChange(option){
    this.setState({
      district : option
    });
  } 
  executiveChange(option){
    var obj_this = this;
    // console.log(option);
    this.setState({
      executive : option,
      district : null
    });
    const url = `${process.env.REACT_APP_SSS}/api/get/executive/wise/districts`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        executive_id:  option.value
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         districts : return_data.districts,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });
  } 

  getTargetReport(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/reports/all/getExeTargetReport`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        executive : this.state.executive?this.state.executive.value:null,
        selected_month  : this.state.monthYear.toLocaleDateString('en-US') ,
        status : this.state.selStatus
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
    
      
      if(return_data.status == "success"){
       
        
        // console.log(updatedData)
        // return
        obj_this.setState({
         customers :  return_data.executives,
         showing_customers: return_data.executives
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.executive){
    //   this.executiveSelect.current.focus();
    //   return;
    // }
    this.getTargetReport();
    
  
   
    
  }


  fromDateChange(value){
    // console.log(value);
    this.setState({from_date : value});
  }
  toDateChange(value){
    // console.log(value);
    this.setState({to_date : value});
  }

  statusChange(option){
    this.setState({
      selStatus : option
    });
  } 

  setMonthYear(option){
    console.log(option)
    this.setState({
      monthYear :option
    })
  }



  
    

  render(){

    return(
      <MyContext.Consumer>
        {context => (
        <div className="exe_target_report_page_container">
          <div className="page_header">
            <Link className="back_button" to="/reports"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Executive Target Report</div>
          </div> 
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
              <div className="input_grid">
                <div className="form_input">
                    
                    <div className="input_label">Month</div>
                    <DatePicker
                      selected={this.state.monthYear}
                      // value = {this.state.monthYear}
                      onChange={(date) => this.setMonthYear(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                 
                  <div className="input_label">Executives</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    ref={this.executiveSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                 
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
                </div>
                
            </div>
          </form>
          <div className="grid_search">
            <div className="search_container">
              <CgSearch/>
              <input className="customer_search_input" 
                placeholder="Search Customers.."
                onChange={this.formChange}
                value={this.search_customer}
                name = "customer"
              />
              
            </div>
           
          </div>
         
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{minWidth:"80px"}} >Executive Name</th>
                  <th style={{minWidth:"80px"}}>Regions Mapped</th>
                  <th style={{minWidth:"420px", textAlign:"center"}}>Target Details</th>
                  <th style={{minWidth:"80px"}}  align="center">Outstanding</th>
                  <th style={{minWidth:"220px"}} align="center">Overdue</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_customers.length?"none":"table-row"}}>
                <td colSpan="8" align="center">No result!</td>
              </tr>
              {this.state.showing_customers && this.state.showing_customers.map((cust,index) => (
                <tr key={index}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" align="left">{cust.label}</td>
                  <td valign="top" >{cust.district_mapped??"Not Mapped"}</td>
                  <td valign="top" style={{padding:"5px 0px", display: cust && cust.category_targets?.length?"":"none"}}>
                    <div className="bills_table_container">
                      <table className="bills_table">
                        <thead>
                          <tr>
                            <th>Category Name</th>
                            <th  style={{minWidth:"80px"}}>Target</th>
                            <th>Achieved Target</th>
                            <th>Pending Target</th>
                            <th>Value of Achieved Target</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cust.category_targets && cust.category_targets.map((target,idx) => (
                          <tr key={idx}>
                            <td>{target.category_name}</td>
                            <td>{target.target} {target.unit??<i className="fas fa-rupee-sign"></i>}</td>
                            <td>{target.target_acheived} {target.unit??<i className="fas fa-rupee-sign"></i>}</td>
                            <td>{target.target_pending} {target.unit??<i className="fas fa-rupee-sign"></i>}</td>
                            <td>{target.acheived_value??0} <i className="fas fa-rupee-sign"></i> </td>

                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td style={{display:cust && cust.category_targets?.length?"none":"table-cell", textAlign:'center'}}>
                    <span >
                      Target not set!
                    </span>
                  </td>
                  <td valign="top" align="center">{cust.tally_bill_outstanding??0 } <i className="fas fa-rupee-sign"></i></td>
                  <td valign="top" align="center">{cust.total_overdue ??0} <i className="fas fa-rupee-sign"></i></td>


                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default ExecutiveTargetReport;
ExecutiveTargetReport.contextType = MyContext;
