import React, { Component } from "react";
import {Link} from "react-router-dom";
import { FiChevronDown } from 'react-icons/fi';

import './Orders.scss';
import MyContext from '../MyContext.jsx';


class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      req_salesorders : [],
      pend_salesorders : [],
      so_confirmed_salesorders : [],
      placed_salesorders : [],
      salesorder_id : '',
      so_reject_reason : '',
      so_reorder_remarks : '',
      so_reorder_special_remarks : ''
    }
    this.formChange = this.formChange.bind(this);
    this.reorderFormSubmit = this.reorderFormSubmit.bind(this);
    this.verifyFormSubmit = this.verifyFormSubmit.bind(this);
    this.escalateFormSubmit = this.escalateFormSubmit.bind(this);
    this.holdFormSubmit = this.holdFormSubmit.bind(this);
    this.cancelFormSubmit = this.cancelFormSubmit.bind(this);
    
  }

  componentDidMount(){    
    this.getSalesOrders();

    var obj_this = this;

    $(".sales_orders_page_container").on("click",".so_verify_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to verify this order")){
        return;
      }
      // var so_approval = obj_this.context.user_details.so_approval;
      const salesorder_id = $(this).attr("salesorder_id");
      // const reporting_to = $(this).attr("reporting_to");
      // if(reporting_to){
      //   so_approval = "";
      // }
      obj_this.setState({
        salesorder_id : $(this).attr("salesorder_id")
      });
      $(".verify_form_ghost").css("display","block");

      

    });

    $(".sales_orders_page_container").on("click",".so_edit_button",function(e){
       e.stopPropagation();
       const salesorder_id = $(this).attr("salesorder_id");
       obj_this.props.history.push("/salesorders/edit/"+salesorder_id);
    });

    $(".sales_orders_page_container").on("click",".single_so_container",function(){
      if($(this).outerHeight() == "60"){
        $(this).heightAuto();
        $(this).find(".arrow_down").css("transform","rotate(180deg)");
      }else{
        $(this).css("height","60px");
        $(this).find(".arrow_down").css("transform","rotate(0deg)");
      }
    });

    $(".sales_orders_page_container").on("click",".asm_reject_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to hold this order")){
        return;
      }
      obj_this.setState({
        salesorder_id : $(this).attr("salesorder_id")
      });
      const salesorder_id = $(this).attr("salesorder_id");
      // const reporting_to = $(this).attr("reporting_to");
      // if(reporting_to){
      //   so_approval = "";
      // }

      $(".hold_form_ghost").css("display","block");

      return;

     
    });

    $(".sales_orders_page_container").on("click",".asm_esc_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to escalate this order")){
        return;
      }
      obj_this.setState({
        salesorder_id : $(this).attr("salesorder_id")
      });
      const salesorder_id = $(this).attr("salesorder_id");
      $(".escalate_form_ghost").css("display","block");
    });

    $(".sales_orders_page_container").on("click",".asm_cancel_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to cancel this order")){
        return;
      }
      obj_this.setState({
        salesorder_id : $(this).attr("salesorder_id")
      });
      const salesorder_id = $(this).attr("salesorder_id");
      $(".cancel_form_ghost").css("display","block");
    });

    $(".close_button").on("click",function(){
      $(".close_form_ghost, .reorder_form_ghost, .verify_form_ghost, .escalate_form_ghost, .hold_form_ghost, .cancel_form_ghost").css("display","none");
      obj_this.setState({
        so_reject_reason : "",
        so_reorder_reason : "",
        so_reorder_remarks : "",
        so_reorder_special_remarks : ""
      });
    });

    $(".sales_orders_page_container").on("click",".so_reorder_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to reorder this order")){
        return;
      }
      obj_this.setState({
        salesorder_id : $(this).attr("salesorder_id")
      });
      const salesorder_id = $(this).attr("salesorder_id");
      $(".reorder_form_ghost").css("display","block");

    });
    ui.swipeMenu(".sales_orders_container");
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  getSalesOrders(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/salesorders`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // return;
      if(return_data.status == "success"){ 
        obj_this.setState(return_data.salesorders);
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }
  
  verifyFormSubmit(e){
    e.preventDefault();
    console.log(this.state);
    var obj_this = this;
    const so_reorder_remarks = obj_this.state.so_reorder_remarks;
    const so_reorder_special_remarks = obj_this.state.so_reorder_special_remarks;
    $(".verify_form_ghost").css("display","none");
    obj_this.setState({
      so_reorder_remarks : "",
      so_reorder_special_remarks : ""
    });
    const url = `${process.env.REACT_APP_SSS}/api/verify/salesorder`;
      obj_this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          salesorder_id : obj_this.state.salesorder_id,
          so_reorder_remarks : so_reorder_remarks,
          so_reorder_special_remarks : so_reorder_special_remarks,
          button_type :"verify"
        },
        type : "POST"
      }).done(function(return_data){
          console.log(return_data);
        if(return_data.status == "verified"){
          obj_this.setState(return_data.salesorders,()=>{
            $(".sales_orders_page_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
          });
          obj_this.context.loading(0);
          alert("Salesorder verified.")
          e.preventDefault();
          const url = `${process.env.REACT_APP_SSS}/api/send/notification`;
            $.ajax({
            url : url,
            data : {
              sid : obj_this.context.user_details.sid,
              status : "SO",
            },
            type : "POST"
          }).done(function(return_data){
              console.log(return_data);
              obj_this.context.loading(0);
          }).fail(function(){
            alert("Network error");
          });
        }else if(return_data.status == "already"){
          obj_this.getSalesOrders();
          alert("Status Already Changed.");

          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

  }

  holdFormSubmit(e){
    e.preventDefault();
    const url = `${process.env.REACT_APP_SSS}/api/verify/salesorder`;
    var obj_this = this;
    obj_this.context.loading(1);
    const so_reorder_reason = obj_this.state.so_reorder_remarks;
    const so_reorder_special_remarks = obj_this.state.so_reorder_special_remarks;
    $(".hold_form_ghost").css("display","none");
    obj_this.setState({
      so_reorder_remarks : "",
      so_reorder_special_remarks : ""
    });
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        salesorder_id : obj_this.state.salesorder_id,
        button_type :"hold",
        so_reorder_remarks : so_reorder_reason,
        so_reorder_special_remarks : so_reorder_special_remarks,
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "hold"){
        obj_this.setState(return_data.salesorders,()=>{
          $(".sales_orders_page_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
        });
        obj_this.context.loading(0);
        alert("Salesorder holded.")
      }else if(return_data.status == "already"){
        obj_this.getSalesOrders();
        alert("Status Already Changed.");

        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

  }

  reorderFormSubmit(e){
    e.preventDefault();
    console.log(this.state);
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/verify/salesorder`;
    obj_this.context.loading(1);
    const so_reorder_reason = obj_this.state.so_reorder_remarks;
    const so_reorder_special_remarks = obj_this.state.so_reorder_special_remarks;
    $(".reorder_form_ghost").css("display","none");
    obj_this.setState({
      so_reorder_remarks : "",
      so_reorder_special_remarks : ""
    });
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        so_reorder_remarks : so_reorder_reason,
        so_reorder_special_remarks : so_reorder_special_remarks,
        salesorder_id : obj_this.state.salesorder_id,
        button_type :"cancel_and_reorder"
      },
      type : "POST"
    }).done(function(return_data){
        // console.log(return_data);
      if(return_data.status == "reorder"){

        obj_this.setState(return_data.salesorders,()=>{
          $(".sales_orders_page_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
        });
        obj_this.context.loading(0);
        alert("Salesorder cancelled and reordered.")
      }else if(return_data.status == "already"){
        obj_this.getSalesOrders();
        alert("Status Already Changed.");

        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  escalateFormSubmit(e){
    e.preventDefault();
    console.log(this.state);
    var obj_this = this;
    const so_reorder_remarks = obj_this.state.so_reorder_remarks;
    const so_reorder_special_remarks = obj_this.state.so_reorder_special_remarks;
    $(".escalate_form_ghost").css("display","none");
    obj_this.setState({
      so_reorder_remarks : "",
      so_reorder_special_remarks : ""
    });
    const url = `${process.env.REACT_APP_SSS}/api/verify/salesorder`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        salesorder_id : obj_this.state.salesorder_id,
        so_reorder_remarks : so_reorder_remarks,
        so_reorder_special_remarks : so_reorder_special_remarks,
        button_type :"escalate"
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "escalate"){
        obj_this.setState(return_data.salesorders,()=>{
          $(".sales_orders_page_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
        });
        obj_this.context.loading(0);
        alert("Sales order escalated!.");
        e.preventDefault();
        const url = `${process.env.REACT_APP_SSS}/api/send/notification`;
        $.ajax({
          url : url,
          data : {
            sid : obj_this.context.user_details.sid,
            status : "APR",
          },
          type : "POST"
        }).done(function(return_data){
            console.log(return_data);
            obj_this.context.loading(0);
        }).fail(function(){
          alert("Network error");
        });      
      }else if(return_data.status == "already"){
          obj_this.getSalesOrders();
          alert("Status Already Changed.");
          obj_this.context.loading(0);

      }
    }).fail(function(){
      alert("Network error");
    });
  }

  cancelFormSubmit(e){
    e.preventDefault();
    const url = `${process.env.REACT_APP_SSS}/api/verify/salesorder`;
    var obj_this = this;
    obj_this.context.loading(1);
    const so_reorder_reason = obj_this.state.so_reorder_remarks;
    const so_reorder_special_remarks = obj_this.state.so_reorder_special_remarks;
    $(".cancel_form_ghost").css("display","none");
    obj_this.setState({
      so_reorder_remarks : "",
      so_reorder_special_remarks : ""
    });
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        salesorder_id : obj_this.state.salesorder_id,
        button_type :"cancel",
        so_reorder_remarks : so_reorder_reason,
        so_reorder_special_remarks : so_reorder_special_remarks,
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "cancel"){
        obj_this.setState(return_data.salesorders,()=>{
          $(".sales_orders_page_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
        });
        obj_this.context.loading(0);
        alert("Salesorder canceled.")
      }else if(return_data.status == "already"){
        obj_this.getSalesOrders();
        alert("Status Already Changed.");

        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

  }
 
  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="sales_orders_page_container noselect">
          <div className="header_spacer"></div>
          <div className="sales_orders_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Salesorder List</div>
            </div>

            <div className="toggles_container">
              <div className="list_1 toggles" menu="0">
                <i className="fa fa-clipboard-list order_status_icon"></i>
                <div className="order_status">Confirmed</div>
                <div className="order_count">{this.state.req_salesorders.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
              <i className="fa fa-check-circle order_status_icon"></i>
                <div className="order_status">Verified</div>
                <div className="order_count">{this.state.pend_salesorders.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fa fa-box order_status_icon"></i>
                <div className="order_status">Escalated</div>
                <div className="order_count">{this.state.so_confirmed_salesorders.length}</div>
              </div>
              <div className="list_4 toggles" menu="3">
              <i className="fa fa-hourglass-start order_status_icon"></i>
                <div className="order_status">Hold</div>
                <div className="order_count">{this.state.placed_salesorders.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.req_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                        <br></br>
                        <div className="so_executive_name"
                        style={{display:item.exe_name?"block":"none"}}>
                         <i className="fas fa-user"></i>{item.exe_name}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}>  
                        <div className="so_outstanding">Outstanding : <i className='fas fa-rupee-sign'></i> {item.total_outstanding?? 0}</div>
                        <div className="so_outstanding">Overdue : <i className='fas fa-rupee-sign'></i> {item.total_overdue?? 0}</div>
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                        
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                   <br></br> 
                   <div className="remarks_style" style={{display:item.remarks?"grid":"none"}}>
                      <span>Remarks :&nbsp;</span>
                      <span>{item.remarks}</span>
                    </div>
                   <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th >Qty</th>
                          <th>Unit</th>
                          <th align="center">SP</th>
                          <th align="center">UP</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" className="sp" >
                              {product.selling_price?
                                <span>
                                  {product.selling_price}/{product.selling_unit}
                                </span> :'-'}
                            </td>

                          <td valign="top" align="right">{parseFloat(product.total/product.qty).toFixed(2)}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                        <th colSpan="2" align="right"></th>
                          <th align="right">{parseFloat(item.products.reduce((qty, product) => qty + parseFloat(product.qty), 0)).toFixed(2)}</th>
                          <th colSpan="3" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                       
                      </tfoot>
                    </table>
                    <div className="so_activity_container">

                    <div className="so_verify_button"
                        salesorder_id = {item.id}
                        reporting_to = {this.context.user_details.staff_id == item.reporting_to?1:0}
                        style={{visibility:this.context.user_details.so_verify && item.status != "SO"?"visible":"hidden"}}
                      >
                         <i className="fas fa-check"></i>
                      </div>
                      <div className="so_reorder_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                      >
                        <i className="fas fa-history"></i>
                      </div>
                       
                      <div className="so_edit_button" 
                        salesorder_id={item.id}
                        style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div>
                      <div className="asm_reject_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                      >
                        <i className="fas fa-hourglass-start"></i>
                      </div>

                      <div className="asm_esc_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                      >
                      <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </div>
                      <div className="asm_cancel_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                      >
                        <b>&times;</b>
                      </div>
                  </div>
                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.pend_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}
                    style={{backgroundColor:item.status == "HOLD"?"rgba(255, 255, 0, 0.31)":item.status == "SOCANCEL"?"rgba(255, 0, 0, 0.26)":""}}
                  >
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                        <br></br>
                        <div className="so_executive_name"
                        style={{display:item.exe_name?"block":"none"}}>
                         <i className="fas fa-user"></i>{item.exe_name}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}>
                      <div className="so_outstanding">Outstanding : <i className='fas fa-rupee-sign'></i> { item.total_outstanding??0}</div>
                      <div className="so_outstanding">Overdue : <i className='fas fa-rupee-sign'></i> { item.total_overdue??0}</div>
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      <div></div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                   <br></br> 
                   <div className="remarks_style" style={{display:item.remarks?"grid":"none"}}>
                      <span>Remarks :&nbsp;</span>
                      <span>{item.remarks}</span>
                    </div>
                    <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">SP</th>
                          <th align="center">UP</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" className="sp" >
                              {product.selling_price?
                                <span>
                                  {product.selling_price}/{product.selling_unit}
                                </span> :'-'}
                          </td>
                          <td valign="top" align="right">{parseFloat(product.total/product.qty).toFixed(2)}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                        <th colSpan="2" align="right"></th>
                          <th align="right">{parseFloat(item.products.reduce((qty, product) => qty + parseFloat(product.qty), 0)).toFixed(2)}</th>
                          <th colSpan="3" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.so_confirmed_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                        <br></br>
                        <div className="so_executive_name"
                        style={{display:item.exe_name?"block":"none"}}>
                         <i className="fas fa-user"></i>{item.exe_name}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}>
                      <div className="so_outstanding">Outstanding : <i className='fas fa-rupee-sign'></i> {item.total_outstanding??0}</div>
                      <div className="so_outstanding">Overdue : <i className='fas fa-rupee-sign'></i> {item.total_overdue??0}</div>
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      <div></div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                   <br></br> 
                   <div className="remarks_style" style={{display:item.remarks?"grid":"none"}}>
                      <span>Remarks :&nbsp;</span>
                      <span>{item.remarks}</span>
                    </div>
                   <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">SP</th>
                          <th align="center">UP</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" className="sp" >
                              {product.selling_price?
                                <span>
                                  {product.selling_price}/{product.selling_unit}
                                </span> :'-'}
                          </td>
                          <td valign="top" align="right">{parseFloat(product.total/product.qty).toFixed(2)}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="2" align="right"></th>
                          <th align="right">{parseFloat(item.products.reduce((qty, product) => qty + parseFloat(product.qty), 0)).toFixed(2)}</th>
                          <th colSpan="3" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="so_activity_container">

                    <div className="so_verify_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify_2 && item.status != "SO"?"visible":"hidden"}}
                      >
                         <i className="fas fa-check"></i>
                      </div>

                      <div className="so_reorder_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify_2?"visible":"hidden"}}
                      >
                        <i className="fas fa-history"></i>
                      </div>
         
                      {/* <div className="so_edit_button" 
                        salesorder_id={item.id}
                        style={{visibility:this.context.user_details.so_verify_2?"visible":"hidden"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div> */}
                      
                      {/* <div className="asm_reject_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify_2?"visible":"hidden"}}
                      >
                        <i className="fas fa-hourglass-start"></i>
                      </div> */}

                      <div className="asm_cancel_button"
                        salesorder_id = {item.id}
                        style={{visibility:this.context.user_details.so_verify_2?"visible":"hidden"}}
                      >
                        <b>&times;</b>
                      </div>
                  </div>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.placed_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                        <br></br>
                        <div className="so_executive_name"
                        style={{display:item.exe_name?"block":"none"}}>
                         <i className="fas fa-user"></i>{item.exe_name}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}>  
                        <div className="so_outstanding">Outstanding : <i className='fas fa-rupee-sign'></i> {item.total_outstanding?? 0}</div>
                        <div className="so_outstanding">Overdue : <i className='fas fa-rupee-sign'></i> {item.total_overdue?? 0}</div>
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                   <br></br> 
                   <div className="remarks_style" style={{display:item.remarks?"grid":"none"}}>
                      <span>Remarks :&nbsp;</span>
                      <span>{item.remarks}</span>
                    </div>
                   <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">SP</th>
                          <th align="center">UP</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" className="sp" >
                              {product.selling_price?
                                <span>
                                  {product.selling_price}/{product.selling_unit}
                                </span> :'-'}
                          </td>
                          <td valign="top" align="right">{parseFloat(product.total/product.qty).toFixed(2)}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                        <th colSpan="2" align="right"></th>
                          <th align="right">{parseFloat(item.products.reduce((qty, product) => qty + parseFloat(product.qty), 0)).toFixed(2)}</th>
                          <th colSpan="3" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="so_activity_container">

                      <div className="so_verify_button"
                          salesorder_id = {item.id}
                          reporting_to = {this.context.user_details.staff_id == item.reporting_to?1:0}
                          style={{visibility:this.context.user_details.so_verify && item.status != "SO"?"visible":"hidden"}}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                        <div className="so_reorder_button"
                          salesorder_id = {item.id}
                          style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                        >
                          <i className="fas fa-history"></i>
                        </div>
                        
                        {/* <div className="so_edit_button" 
                          salesorder_id={item.id}
                          style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                        >
                          <i className="fa fa-pencil-alt"></i>
                        </div> */}

                        <div className="asm_esc_button"
                          salesorder_id = {item.id}
                          style={{visibility:this.context.user_details.so_verify?"visible":"hidden"}}
                        >
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                  </div>
                ))}
              </div>

            </div>

             <div className="reorder_form_ghost">
              <form className="reorder_form" onSubmit={this.reorderFormSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_remarks"
                  value={this.state.so_reorder_remarks}
                  onChange={this.formChange}
                />
                 <div className="input_label">Special Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_special_remarks"
                  value={this.state.so_reorder_special_remarks}
                  onChange={this.formChange}  
                />
                <button className="confirm_reorder_button">REORDER</button>
              </form>
            </div>

            <div className="escalate_form_ghost">
              <form className="reorder_form" onSubmit={this.escalateFormSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_remarks"
                  value={this.state.so_reorder_remarks}
                  onChange={this.formChange}
                />
                 <div className="input_label">Special Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_special_remarks"
                  value={this.state.so_reorder_special_remarks}
                  onChange={this.formChange}  
                />
                <button className="confirm_reorder_button">ESCALATE</button>
              </form>
            </div>
            <div className="verify_form_ghost">
              <form className="reorder_form" onSubmit={this.verifyFormSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_remarks"
                  value={this.state.so_reorder_remarks}
                  onChange={this.formChange}
                />
                 <div className="input_label">Special Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_special_remarks"
                  value={this.state.so_reorder_special_remarks}
                  onChange={this.formChange}  
                />
                <button className="confirm_reorder_button">VERIFY</button>
              </form>
            </div>
            <div className="hold_form_ghost">
              <form className="reorder_form" onSubmit={this.holdFormSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_remarks"
                  value={this.state.so_reorder_remarks}
                  onChange={this.formChange}
                />
                <div className="input_label">Special Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_special_remarks"
                  value={this.state.so_reorder_special_remarks}
                  onChange={this.formChange}  
                />
                <button className="confirm_reorder_button">HOLD</button>
              </form>
            </div>
            <div className="cancel_form_ghost">
              <form className="reorder_form" onSubmit={this.cancelFormSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_remarks"
                  value={this.state.so_reorder_remarks}
                  onChange={this.formChange}
                />
                <div className="input_label">Special Remarks</div>
                <textarea className="custom_input so_reorder_reason"
                  name="so_reorder_special_remarks"
                  value={this.state.so_reorder_special_remarks}
                  onChange={this.formChange}  
                />
                <button className="confirm_reorder_button">CANCEL</button>
              </form>
            </div>
            
          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Order;
Order.contextType = MyContext;
