import React, { Component } from "react";
import {Link} from "react-router-dom";
import { FiChevronDown } from 'react-icons/fi';

import './Leads.scss';
import MyContext from '../MyContext.jsx';


class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_enquiries: [],
      today_followups : [],
      upcoming_followups : [],
    }
  }

  componentDidMount(){    
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getExeLeads`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){ 
        const today_leads = return_data.leads.filter((item) => item.status_date == new Date().toLocaleDateString('en-CA'));
        const today_followups = today_leads.filter((item) => item.status_name == "FOLLOW UP");
        const today_enquiries = today_leads.filter((item) => item.status_name == "NEW LEAD");
        const upcoming_followups = return_data.leads.filter((item) => item.status_date > new Date().toLocaleDateString('en-CA'));
        obj_this.setState({
          today_enquiries: today_enquiries,
          today_followups: today_followups,
          upcoming_followups: upcoming_followups,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    $(".leads_page_container").on("click",".lead_edit_button",function(e){
       e.stopPropagation();
       const lead_id  = $(this).attr("lead_id");
       obj_this.props.history.push("/leads/edit/"+lead_id);
    });

    $(".leads_page_container").on("click",".single_lead_container",function(){
      if($(this).outerHeight() == "60"){
        // if($(this).find(".lead_products_container").css("display") == "none"){
        //   return;
        // }
        $(this).find(".lead_location").heightAuto();
        $(this).heightAuto();
        $(this).find(".arrow_down").css("transform","rotate(180deg)");
      }else{
        $(this).find(".lead_location").css("height","14px");

        $(this).css("height","60px");
        $(this).find(".arrow_down").css("transform","rotate(0deg)");
      }
    });

    ui.swipeMenu(".leads_container");
  }
  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="leads_page_container noselect">
          <div className="header_spacer"></div>
          <div className="leads_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Leads</div>
              <Link className="new_lead_button" 
                to="/leads/new"
                // style={{visibility:this.context.user_details.place_order?"visible":"hidden"}}
              > <i className="fa fa-plus"></i> New</Link>
            </div>

            <div className="toggles_container">
              <div className="list_1 toggles" menu="0">
                <i className="fa fa-clipboard-list status_icon"></i>
                <div className="status">Today Enquiry</div>
                <div className="count">{this.state.today_enquiries.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
                <i className="fa fa-hourglass-start status_icon"></i>
                <div className="status">Todays Followup</div>
                <div className="count">{this.state.today_followups.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fa fa-box status_icon"></i>
                <div className="status">Upcoming Followups</div>
                <div className="count">{this.state.upcoming_followups.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.today_enquiries.map((item,idx)=>(
                  <div className="single_lead_container" key={idx}>
                    <div className="lead_details_container">
                      <div>
                        <div className="lead_customer_name"><i className="fa fa-user"></i> {item.customer_name}</div>
                        <div className="lead_location">Location : {item.location??item.address}</div>
                        <div className="lead_status">Status : {item.status_name}</div>
                      </div>
                      <div></div>
                      <div className="lead_edit_button" 
                         lead_id={item.id}
                        // style={{visibility:this.context.user_details.place_order?"visible":"hidden"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div>
                      
                      <div className="lead_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="lead_products_container"
                      style={{display:item.products.length?"table":"none"}}
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="center">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total(Rs)</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.today_followups.map((item,idx)=>(
                  <div className="single_lead_container" key={idx}
                    style={{backgroundColor:item.status == "HOLD"?"rgba(255, 255, 0, 0.31)":item.status == "SOCANCEL"?"rgba(255, 0, 0, 0.26)":""}}
                  >
                    <div className="lead_details_container">
                      <div>
                        <div className="lead_customer_name"><i className="fa fa-user"></i> {item.customer_name}</div>
                        <div className="lead_location"> Location : {item.location??item.address}</div>
                        <div className="lead_status"> Status : {item.status_name}</div>
                      </div>
                      <div></div>
                      <div className="lead_edit_button" 
                         lead_id={item.id}
                        // style={{visibility:this.context.user_details.place_order?"visible":"hidden"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div>
                      
                      <div className="lead_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="lead_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top" >{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="center">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total(Rs)</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.upcoming_followups.map((item,idx)=>(
                  <div className="single_lead_container" key={idx}>
                    <div className="lead_details_container">
                      <div>
                        <div className="lead_customer_name"><i className="fa fa-user"></i> {item.customer_name}</div>
                        <div className="lead_location"> Location : {item.location??item.address}</div>
                        <div className="lead_status"> Followup Date : {new Date(item.status_date).toLocaleDateString('en-GB')}</div>
                      </div>
                      <div></div>
                      <div className="lead_edit_button" 
                         lead_id={item.id}
                        // style={{visibility:this.context.user_details.place_order?"hidden":"hidden"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div>
                      
                      <div className="lead_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="lead_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="center">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total(Rs)</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>

            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Leads;
Leads.contextType = MyContext;
