import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";

import './Outstanding.scss';

class Outstanding extends Component{
  constructor(props){
    super(props);
    this.executiveSelect = React.createRef();
    this.duePeriodSelect = React.createRef();
    this.state = {
      executives : [],
      executive : "",
      customers : [],
      showing_customers : []
    }
    this.executiveChange = this.executiveChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getOutstandingExecutiveDuration`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/ageingReportByDuration`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      var dealers = return_data.dealers;
      dealers = dealers.sort(function(a,b){
        return a.no_due - b.no_due
      });
      // return;
      if(return_data.status == "success"){
        var dealers = return_data.dealers.filter( (item) => ( item.bills.length));
        obj_this.setState({
         customers : dealers,
         showing_customers : dealers,
         outstanding  : parseFloat(return_data.outstanding).toFixed(2),
         due_exceed : parseFloat(return_data.due_exceed).toFixed(2)
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
    
  }

  executiveChange(option){
    this.setState({
      executive : option
    });
  }  


  formChange(e){
    // console.log(e.target.value);
    var showing_customers =[];
    if(e.target.value){
      showing_customers = this.state.customers.filter((item) => {return item.name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_customers = this.state.customers;
    }
    // showing_customers = showing_customers.length?showing_customers:this.state.customers;
    this.setState({
      showing_customers : showing_customers
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    if(!this.state.executive.value){
      this.executiveSelect.current.focus();
      return;
    }

    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/ageingReportByDuration`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        executive_id : this.state.executive.value,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      var dealers = return_data.dealers;
      dealers = dealers.sort(function(a,b){
        return a.no_due - b.no_due
      });
      // return;
      if(return_data.status == "success"){
        var dealers = return_data.dealers.filter( (item) => ( item.bills.length));
        obj_this.setState({
         customers : dealers,
         showing_customers : dealers,
         outstanding  : parseFloat(return_data.outstanding).toFixed(2),
         due_exceed : parseFloat(return_data.due_exceed).toFixed(2)
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
    
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="customer_outstanding_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Customer Outstandings</div>
          </div> 
          <div className="search_container">
            <CgSearch/>
            <input className="customer_search_input" 
              placeholder="Search customer.."
              onChange={this.formChange}
            />
          </div>
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
              <div className="input_grid">
                <div>
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    ref={this.executiveSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                </div>
               
                <div className="submit_button_container">
                  <button className="submit_button">Submit</button>
                </div>
              </div>
            </div>
          </form>
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th>Customer</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_customers.length?"none":"table-row"}}>
                <td colSpan="2" align="center">No result!</td>
              </tr>
              {this.state.showing_customers && this.state.showing_customers.map((cust,index) => (
                <tr key={index}  style={{backgroundColor:cust.pending_total>0 && !cust.no_due?"":""}}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.name}</div>
                    <div className="bills_table_container" style={{display:cust.bills.length?"block":"none"}}>
                      <table className="bills_table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Invoice No</th>
                            <th>Amount</th>
                            <th>Due Exceeded</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cust.bills && cust.bills.map((bill,idx) => (
                          <tr key={idx} style={{backgroundColor:bill.due_days!='+0' ?"#ffa8b3":""}}>
                            <td>{new Date(bill.bill_date).toLocaleDateString('en-GB')}</td>
                            <td>{bill.bill_no}</td>
                            <td align="right" style={{textAlign:"right"}}>
                              <span>{Math.round(bill.bill_amount)}</span>
                              <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                            <td style={{border:"none",visibility:bill.receivable>0?"visible":"hidden",textAlign:"right"}} align="right">
                              <span >{Math.round(bill.receivable)}</span>
                              <span>({bill.due_days} Days)</span>
                            </td>
                          </tr>
                          ))}
                          <tr className="finalTotal">
                          <td>
                          </td>
                          <td>
                          </td>
                           
                            <td>
                              {cust.pending_total}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                            <td>
                              {cust.exceed_due_total}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
               <tr className="finalTotal">
                <td>

                </td>
                <td>
                  <span> 
                        <span>Outstanding: {this.state.outstanding}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> </span>
                  
                        <span style={{marginLeft:"8px"}}>Due Exceeded: {this.state.due_exceed}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span></span>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default Outstanding;
Outstanding.contextType = MyContext;
