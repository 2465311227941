import React , {Component} from "react";
import {
  BrowserRouter,
  Route
} from "react-router-dom";

import MyProvider from "./components/MyProvider.jsx";
import MyContext from './components/MyContext.jsx';
import Header from "./components/Header/Header.jsx";
import Login from "./components/Login/Login.jsx";
import Home from "./components/Home/Home.jsx";
import SalesOrders from "./components/Sales/Orders.jsx";
import SalesOrder from "./components/Sales/NewOrder.jsx";
import PriceList from "./components/PriceList/PriceList";

import Leads from "./components/Leads/Leads.jsx";
import NewLeads from "./components/Leads/NewLeads.jsx";




import Dashboard from "./components/Dashboard/Dashboard.jsx";


/******** WEB REPORTS *******/

import Outstanding from "./components/Outstanding/Outstanding.jsx";

import Reports from "./components/Reports/ReportList";
import SalesReport from "./components/Reports/SalesReport.jsx";
import DealerWiseReport from "./components/Reports/DealerWiseReport.jsx";
import ExecutiveWiseReport from "./components/Reports/ExecutiveWiseReport.jsx";
import SalesOrderReport from "./components/Reports/SalesOrderReport.jsx";
import AveragePriceReport from "./components/Reports/AveragePriceReport.jsx";
import ExecutiveTargetReport from "./components/Reports/ExecutiveTargetReport.jsx";

/************  WEB REPORTS **************/


import Loading from "./components/Loading/Loading.jsx";
import BottomNavbar from "./components/BottomNavbar/BottomNavbar.jsx";
// import DevTools from "./components/DevTools/DevTools.jsx";
import InDev from "./components/DevTools/InDev.jsx";
class App extends Component{
  render(){
    return (
      <MyProvider> 
        <BrowserRouter>
          <Route path="/" component={Header} />
          <Route exact path="/" component={Login} />
          <Route exact path={window.project_path+"/index.html"} component={Login} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/salesorders" component={SalesOrders} />
          <Route exact path="/salesorders/new" component={SalesOrder} />
          <Route exact path="/salesorders/edit/:sid" component={SalesOrder} />
          <Route exact path="/pricelist" component={PriceList}/>


          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/leads" component={Leads} />
          <Route exact path="/leads/new" component={NewLeads} />
          <Route exact path="/leads/edit/:lead_id" component={NewLeads} />

          {/* REPORTS */}

            <Route exact path="/outstandings" component={Outstanding} />


           <Route exact path="/reports" component={Reports} />
           <Route exact path="/salesreport" component={SalesReport} />
           <Route exact path="/dealerwisereport" component={DealerWiseReport} />
           <Route exact path="/executivewisereport" component={ExecutiveWiseReport} />
           <Route exact path="/salesorderreport" component={SalesOrderReport} />
           <Route exact path="/averagepricereport" component={AveragePriceReport} />
           <Route exact path="/executivetargetreport" component={ExecutiveTargetReport} />


          {/* REPORTS */}

          <Route path="/" component={Loading} />
          <Route path="/" component={BottomNavbar} />
         {/* <Route path="/" component={DevTools} />*/}
        </BrowserRouter>
      </MyProvider>
    );
  }
}

export default App;
App.contextType = MyContext;
